import React, { HTMLAttributes, SFC } from 'react';
import ListItem from '../ListItem';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root' | 'inner'>(theme => ({
  root: {
    boxSizing: 'border-box',
    display: 'inline-block',
    lineHeight: 2,
    margin: '1%',
    padding: '0',
    position: 'relative',
    width: '23%',
  },
  inner: {
    padding: '0 0.5rem',
  },
}));

export interface GridListItemProps extends HTMLAttributes<HTMLDivElement> {}

type Props = WithStyles<GridListItemProps, typeof styles>;

const GridListItem: SFC<Props> = ({
  children,
  classes,
  className,
  theme,
  ...props
}) => (
  <ListItem className={cx(classes.root, className)} {...props}>
    <div className={cx(classes.inner)}>{children}</div>
  </ListItem>
);

export default withStyles(styles)(GridListItem);
