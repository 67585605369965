import React, { HTMLAttributes } from 'react';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root'>(them => ({
  root: {
    display: 'inline-flex',
    position: 'relative',
  },
}));

export interface GridItemProps extends HTMLAttributes<HTMLElement> {
  domRef?: (el: HTMLElement | null) => void;
}

type Props = WithStyles<GridItemProps, typeof styles>;

class GridItem extends React.Component<Props> {
  public render() {
    const { className, classes, domRef, ...rest } = this.props;
    return (
      <div className={cx(classes.root, className)} ref={domRef} {...rest} />
    );
  }
}

export default withStyles(styles)(GridItem);
