import React, { ComponentType } from 'react';
import Theme from '../Theme';

import injectSheet, {
  ConsistentWith,
  InjectOptions,
  Omit,
  PropsOf,
  StyleCreator,
  StyledComponentProps,
  Styles,
  WithSheet,
} from 'react-jss';

type PropInjector<InjectedProps, AdditionalProps = {}> = <
  C extends ComponentType<ConsistentWith<PropsOf<C>, InjectedProps>>
>(
  component: C,
) => ComponentType<
  Omit<JSX.LibraryManagedAttributes<C, PropsOf<C>>, keyof InjectedProps> &
    AdditionalProps
>;

type injectSheet<C extends string> = (
  stylesOrCreator: Styles<C> | StyleCreator<C, Theme>,
  options?: InjectOptions,
) => PropInjector<WithSheet<C, Theme>, StyledComponentProps<C>>;

export type WithStyles<
  TProps,
  S extends string | Styles | StyleCreator<string, any>
> = TProps & WithSheet<any, Theme>;

type injectSheetType = <C extends string, T extends object>(
  stylesOrCreator: Styles<C> | StyleCreator<C, T>,
  options?: InjectOptions,
) => PropInjector<WithSheet<C, T>, StyledComponentProps<C>>;

const withStyles = injectSheet as injectSheetType;

export default withStyles;
