import React, { SFC } from 'react';
import ListSection, { ListSectionProps } from '../ListSection';

export type GridListSectionProps = ListSectionProps;

const GridListSection: SFC<GridListSectionProps> = props => (
  <ListSection {...props} />
);

export default GridListSection;
