import { Theme } from '../theme';
import { createGlobalStyle } from 'styled-components';

export const ThemeVariables = createGlobalStyle<{
  theme: Theme;
  scope: string;
}>`
  ${props => props.scope} {
    ${props => props.theme.toCssVariables()}
  }
`;
