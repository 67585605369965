import { forEach, omit } from 'lodash';
import { Component, RefObject } from 'react';

export type EventHandler<E> = (event: E) => void;

export type EventListenerProps = {
  disabled?: boolean;
  onBlur?: EventHandler<FocusEvent>;
  onBlurCapture?: EventHandler<FocusEvent>;
  onClick?: EventHandler<MouseEvent>;
  onClickCapture?: EventHandler<MouseEvent>;
  onContextMenu?: EventHandler<MouseEvent>;
  onContextMenuCapture?: EventHandler<MouseEvent>;
  onDoubleClick?: EventHandler<MouseEvent>;
  onDoubleClickCapture?: EventHandler<MouseEvent>;
  onFocus?: EventHandler<FocusEvent>;
  onFocusCapture?: EventHandler<FocusEvent>;
  onMouseDown?: EventHandler<MouseEvent>;
  onMouseDownCapture?: EventHandler<MouseEvent>;
  onMouseEnter?: EventHandler<MouseEvent>;
  onMouseLeave?: EventHandler<MouseEvent>;
  onMouseMove?: EventHandler<MouseEvent>;
  onMouseMoveCapture?: EventHandler<MouseEvent>;
  onMouseOut?: EventHandler<MouseEvent>;
  onMouseOutCapture?: EventHandler<MouseEvent>;
  onMouseOver?: EventHandler<MouseEvent>;
  onMouseOverCapture?: EventHandler<MouseEvent>;
  onMouseUp?: EventHandler<MouseEvent>;
  onMouseUpCapture?: EventHandler<MouseEvent>;
  onResize?: EventHandler<Event>;
  onResizeCapture?: EventHandler<Event>;
  target?: string | RefObject<Element> | Element;
};

type Props = EventListenerProps;

const defaultProps = Object.freeze({
  disabled: false,
  target: 'window' as string | RefObject<Element> | Element,
});

const on = (
  target: Element | Window,
  event: string,
  handler: EventHandler<any>,
  options: EventListenerOptions,
) => {
  target.addEventListener(event, handler, options);
};

const off = (
  target: Element | Window,
  event: string,
  handler: EventHandler<any>,
  options: EventListenerOptions,
) => {
  target.removeEventListener(event, handler, options);
};

const getTarget = (
  target?: string | RefObject<Element> | Element,
): Window | Element | null => {
  if (typeof target === 'string') return (window as any)[target];
  if (target instanceof Element) return target;
  if (target) return target.current;
  return null;
};

export const getEventProps = (
  props: EventListenerProps,
): Pick<
  EventListenerProps,
  Exclude<keyof EventListenerProps, 'disabled' | 'target'>
> => omit(props, 'disabled', 'target');

const isCapture = (name: string) => name.substr(-7).toLowerCase() === 'capture';

const convertPropNameToEventName = (name: string) => {
  const capture = isCapture(name);
  const eventName = name.substring(2).toLowerCase();
  return capture ? eventName.substring(0, eventName.length - 7) : eventName;
};

export default class EventListener extends Component<Props> {
  static defaultProps = defaultProps;

  componentDidMount() {
    if (!this.props.disabled) this._apply(on);
  }

  componentDidUpdate(prevProps: Props) {
    this._apply(off, prevProps);
    if (!this.props.disabled) this._apply(on);
  }

  componentWillUnmount() {
    this._apply(off);
  }

  render() {
    return this.props.children;
  }

  private _apply(
    applicator: typeof on | typeof off,
    props: Props = this.props,
  ) {
    const target = getTarget(props.target);
    const eventProps = getEventProps(props);
    if (target) {
      forEach(eventProps, (handler, event) => {
        if (handler) {
          applicator(target, convertPropNameToEventName(event), handler, {
            capture: isCapture(event),
          });
        }
      });
    }
  }
}
