import { ButtonBaseProps } from './button-base-props';
import { Theme } from '@mint/core';

export const resolveTextColor = (
  theme: Theme,
  color: ButtonBaseProps['color'],
): {
  hoverTextColor: string;
  hoverTextColorVar: string;
  textColor: string;
  textColorVar: string;
} => {
  switch (color) {
    case 'primary':
    case 'secondary':
    case 'error':
    case 'warning':
      return {
        hoverTextColor: theme.palette[color].dark,
        hoverTextColorVar: theme.vars.palette[color].dark,
        textColor: theme.palette[color].main,
        textColorVar: theme.vars.palette[color].main,
      };
    case 'inherit':
      return {
        hoverTextColor: 'inherit',
        hoverTextColorVar: '',
        textColor: 'inherit',
        textColorVar: '',
      };
    case 'default':
    default:
      return {
        hoverTextColor: theme.palette.text.primary,
        hoverTextColorVar: theme.vars.palette.text.primary,
        textColor: theme.palette.text.primary,
        textColorVar: theme.vars.palette.text.primary,
      };
  }
};
