import { head, merge, noop } from 'lodash';
import ContainerQuery from '../ContainerQuery';
import Dropzone from '../Dropzone';
import ImagePreview from '../ImagePreview';
import { WithStyles, createStyles, withStyles } from '../styles';
import axios from 'axios';
import { cx } from '../utils';
import DropArea from './DropArea';

// import { ImageProps, ImageSpacer } from '@taffy/images';
// import { ImageUploadResult } from '@waffle/client';

import React, {
  CSSProperties,
  EventHandler,
  ReactNode,
  TransitionEventHandler,
  createRef,
} from 'react';

const styles = createStyles<
  'root' | 'preview' | 'previewLoaded' | 'imageLoaded' | 'spacer' | 'image'
>(() => ({
  root: {
    // display: 'inline-block',
    fontSize: 0,
    // height: 0,
    maxWidth: '100%',
    position: 'relative',
  },
  spacer: {},
  preview: {
    left: 0,
    opacity: 0,
    position: 'absolute',
    top: 0,
    transition: 'opacity 1s',
  },
  image: {
    left: 0,
    opacity: 0,
    position: 'absolute',
    top: 0,
    transition: 'opacity 1s',
  },
  previewLoaded: {
    '& $spacer': {
      display: 'none',
    },
    '& $preview': {
      opacity: 1,
      position: 'relative',
    },
  },
  imageLoaded: {
    '& $spacer': {
      display: 'none',
    },
    '& $preview': {
      opacity: 0,
      position: 'absolute',
    },
    '& $image': {
      display: 'block',
      position: 'relative',
      opacity: 1,
      width: '100%',
    },
  },
}));

type ImageType = 'jpeg' | 'png' | 'webp' | 'gif' | 'svg';

type ImageUploaderRenderProps = {
  onLoad: EventHandler<any>;
  onTransitionEnd: TransitionEventHandler;
  className: string;
};

export type ImageUploaderProps<Result = {}> = {
  accept: ImageType[];
  children: (props: ImageUploaderRenderProps) => ReactNode;
  className?: string;
  filename?: string;
  height: number;
  id?: string;
  onUploadComplete: (result: Result) => void;
  style?: CSSProperties;
  uploadOptions?: any;
  uploadUri?: string;
  width: number;
};
// & Pick<ImageProps, Exclude<keyof ImageProps, 'children' | 'id' | 'filename'>>;

type Props<Result = {}> = WithStyles<ImageUploaderProps<Result>, typeof styles>;

enum UploadState {
  Empty,
  FileSelected,
  FileError,
  PreviewLoaded,
  Uploading,
  UploadError,
  ImageLoading,
  ImageLoaded,
  Done,
}

type State = {
  file?: File;
  fileError?: string | null;
  uploadError?: string | null;
  uploadProgress?: number | null;
  uploadSize?: number | null;
  uploadState?: UploadState;
};

const defaultProps = Object.freeze({
  children: () => null,
  accept: ['jpeg', 'png', 'webp', 'gif', 'svg'] as ImageType[],
  onUploadComplete: noop,
  height: 0,
  width: 0,
});

/**
 * Tests if we have enough information from the component props to render an
 * image from the server.
 */
const propsSatisfyImage = (props: ImageUploaderProps<any>) => {
  const { id, filename } = props;
  return !!(id && filename);
};

const getInitialState = (props: ImageUploaderProps<any>): State => ({
  uploadState: propsSatisfyImage(props) ? UploadState.Done : UploadState.Empty,
});

const imageTypeMap: { [key in ImageType]: string[] } = {
  jpeg: ['image/jpeg'],
  png: ['image/png'],
  webp: ['image/webp'],
  gif: ['image/gif'],
  svg: ['image/svg+xml'],
};

const acceptToMimeTypes = (accept: ImageType[]) =>
  accept.reduce(
    (types, type) => [...types, ...imageTypeMap[type]],
    [] as string[],
  );

class ImageUploader<Result = {}> extends React.PureComponent<
  Props<Result>,
  State
> {
  static defaultProps = defaultProps;
  state = getInitialState(this.props);

  private _containerRef = createRef<HTMLDivElement>();
  private _ajax: any | null = null;

  componentWillUnmount() {
    // TODO: Cancel AJAX.
    // if (this._ajax) this._ajax.cancel();
  }

  render() {
    const { accept, children, classes, className } = this.props;
    const uploadState = this.state.uploadState as UploadState;
    return (
      <Dropzone accept={acceptToMimeTypes(accept)} onDrop={this._handleDrop}>
        {({
          dragover,
          files,
          onDrop,
          onDragEnter,
          onDragLeave,
          onDragOver,
        }) => {
          const file = head(files);
          return (
            <ContainerQuery
              target={this._containerRef}
              sizes={[
                [{ maxHeight: 199, maxWidth: 199 }, { compact: true }],
                [{ minHeight: 199 }, { vertical: true }],
              ]}
            >
              {sizes => (
                <div
                  className={cx(
                    classes.root,
                    uploadState >= UploadState.PreviewLoaded &&
                      uploadState <= UploadState.ImageLoaded &&
                      classes.previewLoaded,
                    uploadState >= UploadState.ImageLoaded &&
                      classes.imageLoaded,
                    className,
                  )}
                  ref={this._containerRef}
                  style={this._getStyle()}
                  {...{ onDrop, onDragEnter, onDragLeave, onDragOver }}
                >
                  <DropArea
                    {...(merge as any)(...sizes)}
                    empty={this.state.uploadState === UploadState.Empty}
                    dragover={dragover}
                    icon="camera"
                    dragLabel="Drag an image here to upload"
                    dropLabel="Drop to start uploading."
                  />
                  {/* <ImageSpacer
                    className={classes.spacer}
                    height={this.props.height}
                    width={this.props.width}
                  /> */}
                  {file && (
                    <ImagePreview
                      className={classes.preview}
                      height={this.props.height}
                      width={this.props.width}
                      file={file}
                      onTransitionEnd={this._handlePreviewTransitionEnd}
                      onLoad={this._handlePreviewLoad}
                    />
                  )}
                  {children &&
                    children({
                      className: classes.image,
                      onTransitionEnd: this._handleImageTransitionEnd,
                      onLoad: this._handleImageLoaded,
                    })}
                </div>
              )}
            </ContainerQuery>
          );
        }}
      </Dropzone>
    );
  }

  getDOMNode(): HTMLElement | null {
    return this._containerRef.current;
  }

  private _getStyle() {
    const { height, style, width = 1 } = this.props;
    return {
      // Use padding to set an aspect ratio.
      // paddingBottom: `${(height / width) * 100}%`,
      ...style,
    };
  }

  private _handlePreviewLoad = () => {
    this.setState({ uploadState: UploadState.PreviewLoaded });
  };

  private _handleImageLoaded = () => {
    const { uploadState } = this.state;
    if (
      typeof uploadState === 'number' &&
      uploadState < UploadState.ImageLoaded
    ) {
      this.setState({ uploadState: UploadState.ImageLoaded });
    }
  };

  private _handlePreviewTransitionEnd = () => {
    const { uploadOptions, uploadUri } = this.props;
    const { file, uploadState } = this.state;
    if (!file || typeof uploadState !== 'number') return;
    if (!uploadUri) return;
    if (uploadState < UploadState.Uploading) {
      const data = new FormData();
      data.append('image', file);
      this._handleUploadStart();
      axios
        .post('/api/v0/images', data, {
          onUploadProgress: this._handleUploadProgress,
        })
        .then(this._handleUploadComplete)
        .catch(this._handleUploadError);
    }
  };

  private _handleImageTransitionEnd = () => {
    if (this.state.uploadState === UploadState.ImageLoaded) {
      this.setState({ uploadState: UploadState.Done });
    }
  };

  private _handleDrop = (files: File[]) => {
    const file = head(files);
    if (!file) return;
    // TODO: handle file error State.FileError
    this.setState({
      file,
      fileError: null,
      // filename: file.name,
      uploadError: null,
      uploadProgress: null,
      uploadSize: null,
      uploadState: UploadState.FileSelected,
    });
  };

  private _handleUploadProgress = (e: ProgressEvent) => {
    this.setState({
      uploadProgress: e.loaded,
      uploadSize: e.total,
    });
  };

  private _handleUploadStart = () => {
    this.setState({
      uploadState: UploadState.Uploading,
    });
  };

  private _handleUploadComplete = (response: any) => {
    this.setState({ uploadState: UploadState.ImageLoading }, () =>
      this.props.onUploadComplete(response.data),
    );
  };

  private _handleUploadError = (err: any) => {
    this.setState({
      uploadError: 'Error uploading image.',
    });
  };
}

export default withStyles(styles)(ImageUploader);
