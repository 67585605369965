import EventEmitter from 'eventemitter3';

export class Flip extends EventEmitter {
  public static create(
    element: HTMLElement,
    options?: KeyframeAnimationOptions,
  ) {
    return new Flip(element, options);
  }

  get running() {
    if (this._animation) return this._animation.playState === 'running';
    return false;
  }

  private _el: HTMLElement;
  private _first: ClientRect | DOMRect | null = null;
  private _animation: Animation | null = null;
  private _options: KeyframeAnimationOptions | null;

  constructor(
    element: HTMLElement,
    options: KeyframeAnimationOptions | null = null,
  ) {
    super();
    this._el = element;
    this._options = options;
    this.first();
  }

  public first() {
    this._first = this._el.getBoundingClientRect();
  }

  public animate() {
    const first = this._first;
    if (!first) return;

    const last = this._el.getBoundingClientRect();

    const deltaX = first.left - last.left;
    const deltaY = first.top - last.top;
    const deltaW = first.width / last.width;
    const deltaH = first.height / last.height;

    requestAnimationFrame(() => {
      this._animation = this._el.animate(
        [
          {
            transform: `
              translate(${deltaX}px, ${deltaY}px)
              scale(${deltaW}, ${deltaH})
            `,
            transformOrigin: 'top left',
          },
          {
            transform: 'none',
            transformOrigin: 'top left',
          },
        ] as Keyframe[],
        {
          duration: 300,
          easing: 'ease-in-out',
          fill: 'both',
          ...this._options,
        },
      );

      this._animation.addEventListener('cancel', this._handleCancel);
      this._animation.addEventListener('finish', this._handleFinish);

      this.emit('play');
    });
  }

  public play(): this {
    if (this._animation) {
      this.emit('play');
      this._animation.play();
    }
    return this;
  }

  public pause(): this {
    if (this._animation) {
      this.emit('pause');
      this._animation.pause();
    }
    return this;
  }

  public cancel(): this {
    if (this._animation) {
      this._animation.cancel();
    }
    return this;
  }

  public finish(): this {
    if (this._animation) {
      this._animation.finish();
    }
    return this;
  }

  public reverse(): this {
    if (this._animation) {
      this._animation.reverse();
    }
    return this;
  }

  private _handleCancel = () => {
    this.emit('cancel');
  };

  private _handleFinish = () => {
    this.emit('finish');
  };
}

export default (element: HTMLElement, options?: KeyframeAnimationOptions) =>
  Flip.create(element, options);
