import { ButtonLabelProps } from './button-label-props';
import { ButtonText } from '../typography';
import React from 'react';
import styled from 'styled-components';

const ButtonLabelComponent = React.forwardRef<any, ButtonLabelProps>(
  (props, ref): React.ReactElement => {
    const { color, variant, ...rest } = props;
    return <ButtonText {...rest} ref={ref} />;
  },
);

ButtonLabelComponent.displayName = 'ButtonLabel';

export const ButtonLabel = styled(ButtonLabelComponent)`
  display: flex;
  transition: color 120ms;
`;
