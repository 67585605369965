import { noop } from 'lodash';
import { createContext } from 'react';

export interface SortableContextType {
  endSort: () => void;
  startSort: (sortKey: string, target: HTMLElement) => void;
  sortingKey: string | null;
}

export default createContext<SortableContextType>({
  endSort: noop,
  startSort: noop,
  sortingKey: null,
});
