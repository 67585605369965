import React, { HTMLAttributes, SFC } from 'react';
import List from '../List';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root'>(theme => ({
  root: {
    ...theme.font('control'),
    boxSizing: 'border-box',
    display: 'block',
    maxWidth: '100%',
    padding: '0.3em 0',
  },
}));

export interface MenuProps extends HTMLAttributes<HTMLDivElement> {}

type Props = WithStyles<MenuProps, typeof styles>;

const Menu: SFC<Props> = ({
  children,
  classes,
  className,
  theme,
  ...props
}) => (
  <div className={cx(classes.root, className)} {...props}>
    <List>{children}</List>
  </div>
);

export default withStyles(styles)(Menu);
