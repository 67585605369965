import React, { ReactNode, SFC } from 'react';
import Ink from '../Ink';
import ListItem from '../ListItem';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root'>(theme => ({
  root: {
    backgroundColor: theme.color('background'),
    cursor: 'pointer',
    position: 'relative',
  },
}));

export type ListPickerOptionType<V = {}> = {
  key: string;
  label: ReactNode;
  value: V;
};

export interface ListPickerOptionProps<V> extends ListPickerOptionType<V> {
  className?: string;
  inkClassName?: string;
  onClick?: (value: V) => void;
  labelClassName?: string;
}

type Props<V> = WithStyles<ListPickerOptionProps<V>, typeof styles>;

const ListPickerOption: SFC<Props<any>> = ({
  classes,
  className,
  onClick,
  label,
  labelClassName,
}) => (
  <ListItem className={cx(classes.root, className)} onClick={onClick}>
    <div className={cx(labelClassName)}>{label}</div>
    <Ink />
  </ListItem>
);

export default withStyles(styles)(ListPickerOption);
