import { ButtonGroupProps } from './button-group-props';
import React from 'react';
import { cx } from '@mint/core';
import styled from 'styled-components';

/**
 * List root class name.
 */
const ROOT_CLASS_NAME = 'button-group';

/**
 * Component to use.
 */
const DEFAULT_COMPONENT = 'div';

/**
 * List component implementation before styling.
 */
export const ButtonGroupComponent = React.forwardRef<any, ButtonGroupProps>(
  (props, ref): React.ReactElement => {
    const {
      children,
      className,
      color,
      component: Component = DEFAULT_COMPONENT,
      theme: _,
      ...rest
    } = props;

    const componentProps = {
      className: cx(ROOT_CLASS_NAME, className),
      ...rest,
      ref,
    };

    return (
      <Component {...componentProps} color={color}>
        {children}
      </Component>
    );
  },
);

ButtonGroupComponent.displayName = 'ButtonGroup';

export const ButtonGroup = styled(ButtonGroupComponent)``;
