/**
 * Generates a random string.
 * @param length The length of the random string.
 * @param base Base output of random string.
 */
export default (length: number = 6, base: number = 36): string => {
  let accumulator = '';
  while (accumulator.length < length) {
    accumulator += Math.random()
      .toString(base)
      .substring(2);
  }
  return accumulator.substring(0, length);
};
