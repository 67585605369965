import { Theme } from '../theme';
import { ThemeAssertionError } from '../errors';

export const assertTheme = <T extends { theme?: Theme }>(
  input: T | Theme | undefined | null,
): Theme => {
  if ((input as Theme) instanceof Theme) {
    return input as Theme;
  } else if (
    input &&
    (input as T).theme &&
    (input as T).theme instanceof Theme
  ) {
    return (input as T).theme as Theme;
  }
  throw new ThemeAssertionError();
};
