import React, { HTMLAttributes, ReactNode, SFC } from 'react';
import Ink from '../Ink';
import ListItem from '../ListItem';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root' | 'active' | 'icon'>(theme => ({
  root: {
    background: theme.color('background'),
    cursor: 'pointer',
    maxWidth: '100%',
    padding: '0 0.5em',
    position: 'relative',
    transition: 'background-color 100ms',
    userSelect: 'none',
    whiteSpace: 'nowrap',
    '&:hover': {
      background: theme.color('grey', '50'),
    },
  },
  active: {
    background: theme.color('grey', '50'),
  },
  icon: {
    fontSize: '0.9em',
    marginLeft: '-0.2em',
    marginRight: '0.5em',
    position: 'relative',
  },
}));

export interface MenuItemProps extends HTMLAttributes<HTMLDivElement> {
  active?: boolean;
  icon?: ReactNode;
}

type Props = WithStyles<MenuItemProps, typeof styles>;

const MenuItem: SFC<Props> = ({
  active,
  children,
  classes,
  className,
  icon,
  theme,
  ...rest
}) => (
  <ListItem
    className={cx(classes.root, active && classes.active, className)}
    {...rest}
  >
    {icon}
    {children}
    <Ink />
  </ListItem>
);

export default withStyles(styles)(MenuItem);
