import React, { SFC } from 'react';
import Paper, { PaperProps } from '../Paper';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root' | 'rounded' | 'vertical'>(theme => ({
  root: {
    ...theme.font('control'),
    alignItems: 'center',
    color: theme.color('text.body'),
    display: 'flex',
    height: '1.6em',
    fontSize: (({ size = 14 }: ToolbarProps) => size) as any,
    margin: 0,
    padding: 2,
    position: 'relative',
  },
  rounded: {
    borderRadius: '1em',
    paddingLeft: '0.5em',
    paddingRight: '0.5em',
  },
  vertical: {
    '&$rounded': {
      paddingBottom: '0.5em',
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: '0.5em',
    },
  },
}));

export interface ToolbarProps extends PaperProps {
  vertical?: boolean;
  size?: number;
  rounded?: boolean;
}

type Props = WithStyles<ToolbarProps, typeof styles>;

const Toolbar: SFC<Props> = ({
  children,
  classes,
  className,
  domRef,
  elevation,
  rounded,
  theme,
  vertical,
  ...rest
}) => (
  <Paper
    elevation={elevation}
    className={cx(
      classes.root,
      vertical && classes.vertical,
      rounded && classes.rounded,
      className,
    )}
    domRef={domRef}
    {...rest}
  >
    {children}
  </Paper>
);
export default withStyles(styles)(Toolbar);
