import React, { SFC } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import Ink from '../Ink';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<
  | 'disabled'
  | 'error'
  | 'primary'
  | 'root'
  | 'secondary'
  | 'success'
  | 'warning'
  | 'ink'
>(theme => ({
  root: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'inline-flex',
    justifyContent: 'center',
    lineHeight: 1,
    outline: 0,
    position: 'relative',
    transition: 'color 140ms, filter 140ms',
    '& $ink': {
      filter: 'brightness(70%)',
      height: '140%',
      left: '50%',
      opacity: 0.6,
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: '140%',
    },
  },
  disabled: {
    color: theme.color('text.disabled'),
  },
  primary: {
    color: theme.color('primary'),
    '&:hover': {
      color: theme.color('primary', '700'),
    },
  },
  secondary: {
    color: theme.color('secondary'),
    '&:hover': {
      color: theme.color('secondary', '700'),
    },
  },
  error: {
    color: theme.color('error'),
    '&:hover': {
      color: theme.color('error', '700'),
    },
  },
  success: {
    color: theme.color('success'),
    '&:hover': {
      color: theme.color('success', '700'),
    },
  },
  warning: {
    color: theme.color('warning'),
    '&:hover': {
      color: theme.color('warning', '700'),
    },
  },
  ink: {},
}));

export interface IconButtonProps
  extends Pick<Partial<LinkProps>, Exclude<keyof LinkProps, 'innerRef'>> {
  disabled?: boolean;
  loading?: boolean;
  type?: 'primary' | 'secondary' | 'error' | 'success' | 'warning';
}

type Props = WithStyles<IconButtonProps, typeof styles>;

const getTag = ({ disabled, href, to }: IconButtonProps) => {
  if (!disabled && to) return Link;
  if (!disabled && href) return 'a';
  return 'span';
};

const IconButton: SFC<Props> = props => {
  const {
    children,
    classes,
    className,
    disabled,
    theme,
    type,
    ...rest
  } = props;

  // FIXME: Since TS 3.2, it cannot determine the type of `Tag` as callable.
  const Tag: any = getTag(props);

  return (
    <Tag
      className={cx(classes.root, type && classes[type], className)}
      tabIndex={disabled ? -1 : 0}
      role={'button'}
      {...rest}
    >
      <Ink className={classes.ink} duration={100} center contain />
      {children}
    </Tag>
  );
};

export default withStyles(styles)(IconButton);
