import styled from 'styled-components';

export const InkContainer = styled.div`
  border-radius: inherit;
  height: 100%;
  left: 0;
  opacity: 0.28;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
`;

InkContainer.displayName = 'InkContainer';
