import React, { HTMLAttributes, SFC, createElement } from 'react';
import SortableItem from '../SortableItem';
import { cx } from '../utils';

/**
 * Default tag to render if one isn't specified.
 */
const DEFAULT_TAG = 'div';

/**
 * Properties for the <Sortable/> component.
 * @property order The order of this sortable item (set by parent container).
 * @property sortClassName Class to apply while being sorted.
 * @property sortKey The sort key for this item.
 * @property tag The tag to use when rendering this component.
 */
export interface SortableProps extends HTMLAttributes<any> {
  disabled?: boolean;
  order?: number;
  sortClassName?: string;
  sortKey: string;
  tag: keyof JSX.IntrinsicElements;
}

/**
 * Convenience component for quickly and easily creating a sortable item.
 * @param props Component properties.
 */
const Sortable: SFC<SortableProps> = ({
  className,
  disabled,
  sortClassName,
  sortKey,
  tag = DEFAULT_TAG,
  order,
  ...rest
}) => (
  <SortableItem sortKey={sortKey} order={order} disabled={disabled}>
    {({ domRef, onMouseDown, onTouchStart, sorting, style }) =>
      createElement(tag, {
        ...rest,
        'data-sort-key': sortKey,
        className: cx(sorting && sortClassName, className),
        onMouseDown,
        onTouchStart,
        ref: domRef,
        style: {
          ...rest.style,
          ...style,
        },
      })
    }
  </SortableItem>
);

export default Sortable;
