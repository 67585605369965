import { CSSProperties, StyleCreator } from 'react-jss';
import Theme from '../Theme';

type CSSValue<TProps> = {
  [K in keyof CSSProperties<any>]:
    | CSSProperties<any>[K]
    | ((props: TProps) => CSSProperties<any>[K])
};

type Styles<TProps, ClassKey extends string = string> = Record<
  ClassKey,
  CSSValue<TProps>
>;

export default <ClassKey extends string = string, TProps = {}>(
  fn: (theme: Theme) => Styles<TProps, ClassKey>,
): StyleCreator<ClassKey, Theme> => (t: any) => fn(Theme.fromJSON(t)) as any;
