import { Quill as TQuill } from 'quill';
declare const Quill: typeof TQuill;

export default () => {
  const Parchment = Quill.import('parchment');

  const config = {
    scope: Parchment.Scope.BLOCK,
    whitelist: ['right', 'center', 'justify'],
  };

  const AlignAttribute = new Parchment.Attributor.Attribute(
    'align',
    'align',
    config,
  );

  const AlignClass = new Parchment.Attributor.Class(
    'align',
    'text-align-',
    config,
  );

  const AlignStyle = new Parchment.Attributor.Style(
    'align',
    'text-align',
    config,
  );

  return { AlignAttribute, AlignClass, AlignStyle };
};
