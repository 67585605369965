import { noop } from 'lodash';

import {
  Context,
  MouseEventHandler,
  TouchEventHandler,
  createContext,
} from 'react';

export interface DragContextType {
  endDrag: () => void;
  startDrag: MouseEventHandler<HTMLElement> | TouchEventHandler<HTMLElement>;
}

const DragContext: Context<DragContextType> = createContext({
  endDrag: noop,
  startDrag: noop,
});

export default DragContext;
