import React, { HTMLAttributes, SFC } from 'react';
import CSSTransition from '../CSSTransition';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root' | 'focused' | 'error'>(theme => ({
  root: {
    ...theme.font('control'),
    bottom: '-1.3em',
    color: theme.color('text.hint'),
    fontSize: '0.8em',
    opacity: 1,
    position: 'absolute',
    transition: 'opacity 200ms',
    userSelect: 'none',
  },
  focused: {
    opacity: 1,
  },
  error: {
    color: theme.color('error'),
  },
}));

export interface InputMessageProps extends HTMLAttributes<HTMLDivElement> {
  domRef?: (el: HTMLElement | null) => void;
  focused?: boolean;
  error?: boolean;
}

type Props = WithStyles<InputMessageProps, typeof styles>;

const InputMessage: SFC<Props> = ({
  children,
  className,
  classes,
  error,
  focused,
}) => (
  <CSSTransition transitionClassNames="input-message">
    {({ domRef, className: transitionClassName, ...rest }) => (
      <div
        className={cx(
          classes.root,
          className,
          error && classes.error,
          focused && classes.focused,
          transitionClassName,
        )}
        ref={domRef}
        {...rest}
      >
        {children}
      </div>
    )}
  </CSSTransition>
);

export default withStyles(styles)(InputMessage);
