import { isElement } from 'lodash';
import { invariant } from '../utils';

export default (domRef: any, sortKey: string) => {
  invariant(
    domRef,
    [
      `Cannot start sort. Ref for sortable ${sortKey} is not set.`,
      `Make sure you used the \`domRef\` prop correctly.`,
    ].join(' '),
  );
  invariant(
    isElement(domRef),
    [
      `Cannot start sort. Ref for sortable ${sortKey} is not an HTMLElement.`,
      `Make sure you used the \`domRef\` can obtain an HTMLElement ref.`,
    ].join(' '),
  );
};
