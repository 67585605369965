import React, { HTMLAttributes, PureComponent, ReactNode } from 'react';
import Scroller from '../Scroller';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root' | 'scroller'>(theme => {
  const height: any = (props: PickerProps) => props.height;
  const width: any = (props: PickerProps) => props.width;
  return {
    root: {
      height,
      width,
      display: 'flex',
      flexDirection: 'column',
    },
    scroller: {
      flex: 1,
    },
  };
});

// export type Option<V = {}> = {
//   key: string;
//   title: ReactNode;
//   value: V;
// };

// export type OrderedOptions<V = {}> = [
//   string | { title: ReactNode; key: string },
//   Option<V>[]
// ][];

export interface PickerProps extends HTMLAttributes<HTMLDivElement> {
  footer?: ReactNode;
  header?: ReactNode;
  height?: number;
  width?: number;
}

type Props = WithStyles<PickerProps, typeof styles>;

const defaultProps = Object.freeze({
  height: 300,
  width: 280,
});

class Picker extends PureComponent<Props> {
  static defaultProps = defaultProps;

  render() {
    const {
      children,
      classes,
      className,
      footer,
      header,
      onScroll,
      ...rest
    } = this.props;
    return (
      <div className={cx(classes.root, className)} {...rest}>
        {header}
        <Scroller onScroll={onScroll} className={classes.scroller}>
          {children}
        </Scroller>
        {footer}
      </div>
    );
  }
}

export default withStyles(styles)(Picker);
