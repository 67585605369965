import { camelCase } from 'lodash';
import React, { CSSProperties, SFC } from 'react';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';
import ResizeContext, { ResizeAnchor } from './ResizeContext';

const styles = createStyles<
  | 'handle'
  | 'east'
  | 'north'
  | 'northEast'
  | 'northWest'
  | 'south'
  | 'southEast'
  | 'southWest'
  | 'west'
>(() => ({
  handle: {
    display: 'block',
    height: 6,
    position: 'absolute',
    width: 6,
    zIndex: 2,
  },
  east: {
    cursor: 'ew-resize',
    height: '100%',
    right: 0,
    top: 0,
  },
  north: {
    cursor: 'nw-resize',
    left: 0,
    top: 0,
    width: '100%',
  },
  northEast: {
    cursor: 'nesw-resize',
    right: 0,
    top: 0,
  },
  northWest: {
    cursor: 'nwse-resize',
    left: 0,
    top: 0,
  },
  south: {
    bottom: 0,
    cursor: 'ns-resize',
    left: 0,
    width: '100%',
  },
  southEast: {
    bottom: 0,
    cursor: 'nwse-resize',
    right: 0,
  },
  southWest: {
    bottom: 0,
    cursor: 'nesw-resize',
    left: 0,
  },
  west: {
    cursor: 'ew-resize',
    height: '100%',
    left: 0,
    top: 0,
  },
}));

export interface ResizeHandlesProps {
  className?: string;
  style?: CSSProperties;
  children?: never;
  sides?: ResizeAnchor[];
}

type Props = WithStyles<ResizeHandlesProps, typeof styles>;

const getResizeRestrict = (side: string) => {
  switch (side) {
    case 'north':
    case 'south':
      return 'y';
    case 'east':
    case 'west':
      return 'x';
    default:
      return undefined;
  }
};

const ResizeHandles: SFC<Props> = ({
  classes,
  sides = [
    'north',
    'south',
    'east',
    'west',
    'north-west',
    'north-east',
    'south-west',
    'south-east',
  ] as ResizeAnchor[],
  className,
  style,
}) => (
  <ResizeContext.Consumer>
    {({ startResize }) =>
      sides.map((side: ResizeAnchor) => (
        <span
          key={side}
          className={cx(
            classes.handle,
            (classes as any)[camelCase(side)],
            className,
          )}
          onMouseDown={(e: React.MouseEvent) =>
            startResize(e, { anchor: side, restrict: getResizeRestrict(side) })
          }
          style={style}
        />
      ))
    }
  </ResizeContext.Consumer>
);

export default withStyles(styles)(ResizeHandles);
