import React, { HTMLAttributes, SFC } from 'react';
import ToolbarItem from '../ToolbarItem';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root'>(theme => ({
  root: {
    background: theme.color('grey', '200'),
    height: '100%',
    margin: '0 4px',
    minWidth: 0,
    padding: 0,
    pointerEvents: 'none',
    position: 'relative',
    width: 1,
  },
}));

export interface ToolbarSeparatorProps extends HTMLAttributes<HTMLDivElement> {}

type Props = WithStyles<ToolbarSeparatorProps, typeof styles>;

const ToolbarSeparator: SFC<Props> = ({
  children,
  classes,
  className,
  theme,
  ...rest
}) => (
  <ToolbarItem
    aria-hidden="true"
    className={cx(classes.root, className)}
    {...rest}
  />
);

export default withStyles(styles)(ToolbarSeparator);
