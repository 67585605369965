import { partial } from 'lodash';
import SortableContext, { SortableContextType } from '../SortableContext';
import { Flip, flip } from '../utils';
import sortableInvariant from './sortableInvariant';

import React, {
  CSSProperties,
  Component,
  MouseEvent,
  MouseEventHandler,
  ReactElement,
  ReactNode,
  TouchEvent,
  TouchEventHandler,
  cloneElement,
} from 'react';

/**
 * Properties or render props passed to child element or function.
 * @property domRef Function to get reference to base DOM element.
 * @property endSort Force-end the sort.
 * @property onMouseDown Convenience fn for mouse events to start sort.
 * @property onTouchStart Convenience fn for touch event to start sort.
 * @property order The current sorted order of this item.
 * @property sorting `true` if this item is currently being sorted.
 * @property sortInProgress `true` if any element is being sorted.
 * @property startSort Function to call to start sorting this item.
 * @property style Convenience styles to set on sorting DOM node.
 */
export interface SortableItemChildProps {
  domRef: (el: HTMLElement | null) => void;
  endSort: () => void;
  onMouseDown: MouseEventHandler;
  onTouchStart: TouchEventHandler;
  order?: number;
  sorting?: boolean;
  sortInProgress: boolean;
  startSort: () => void;
  style: CSSProperties;
}

/**
 * Component properties.
 * @property children Function or ReactElement child.
 * @property order The current order of this sortable item (set by parent).
 * @property sortKey The sort-key for this item (must be set).
 */
export interface SortableItemProps {
  children:
    | ReactElement<SortableItemChildProps>[]
    | ((props: SortableItemChildProps) => ReactNode);
  disabled?: boolean;
  order?: number;
  sortKey: string;
}

/**
 * Internal component state interface.
 * @property animating `true` if this item is currently being animated.
 */
interface State {
  animating?: boolean;
}

/**
 * Default component properties.
 */
const defaultProps = Object.freeze({
  children: () => null,
});

/**
 * Initial component state object.
 */
const initialState: State = Object.freeze({
  animating: false,
});

/**
 * SortableItem component used to wrap a sortable item.
 */
export default class SortableItem extends Component<SortableItemProps, State> {
  static defaultProps = defaultProps;
  state = initialState;

  private _ctx: SortableContextType | null = null;
  private _domRef: HTMLElement | null = null;

  /* ---------------------------------------------------------------------------
   * Lifecycle Methods
   * ------------------------------------------------------------------------ */

  /**
   * Builds a FLIP animation before an update occurs, if this item should be
   * animated. (SortableItems which are the target, e.g. the one being dragged,
   * should not be animated.)
   * @param prevProps Previous component properties.
   */
  getSnapshotBeforeUpdate(prevProps: SortableItemProps) {
    if (this._shouldAnimate(prevProps)) {
      return flip(this._domRef as HTMLElement, { duration: 200 })
        .on('play', this._handleAnimationPlay)
        .on('finish', this._handleAnimationFinish);
    }
    return null;
  }

  /**
   * Plays the FLIP animation (if present), moving the element after an
   * update occurs.
   * @param prevProps Previous component properties.
   * @param prevState Previous component state.
   * @param anim FLIP animation, if one should be played.
   */
  componentDidUpdate(
    prevProps: SortableItemProps,
    prevState: State,
    anim: Flip | null,
  ) {
    if (this._ctx && !this._isSorting(this._ctx) && anim) {
      anim.animate();
    }
  }

  /**
   * Renders the component.
   */
  render() {
    return (
      <SortableContext.Consumer>
        {ctx => {
          this._ctx = ctx;
          return this._renderChildren(ctx);
        }}
      </SortableContext.Consumer>
    );
  }

  /* ---------------------------------------------------------------------------
   * Children Rendering and Props Methods
   * ------------------------------------------------------------------------ */

  /**
   * Renders the children of this component.
   * @param ctx Sortable context value.
   */
  private _renderChildren(ctx: SortableContextType) {
    const { children } = this.props;
    const props = this._getChildProps(ctx);
    if (typeof children === 'function') {
      return children(props);
    } else {
      return cloneElement(
        children as ReactElement<SortableItemChildProps>,
        props,
      );
    }
  }

  /**
   * Builds the properties to pass to the child.
   * @param ctx Sortable context value.
   */
  private _getChildProps(ctx: SortableContextType): SortableItemChildProps {
    return {
      startSort: partial(this._startSort, ctx),
      domRef: this._setDomRef,
      endSort: ctx.endSort,
      onMouseDown: partial(this._handleMouseDown, ctx),
      onTouchStart: partial(this._handleTouchStart, ctx),
      sorting: this._isSorting(ctx),
      sortInProgress: ctx.sortingKey != null,
      style: this._getStyle(ctx),
    };
  }

  /**
   * Builds the style object which is passed to the child.
   * @param ctx Sortable context value.
   */
  private _getStyle(ctx: SortableContextType): CSSProperties {
    if (this._isSorting(ctx) || this.state.animating) {
      return {
        pointerEvents: 'none',
      };
    }
    return {};
  }

  /* ---------------------------------------------------------------------------
   * Sort Start/End Handlers
   * ------------------------------------------------------------------------ */

  /**
   * Starts a sort and ensures the component has all the information required
   * to properly execute a sort-start.
   */
  private _startSort = (ctx: SortableContextType) => {
    const { sortKey } = this.props;
    sortableInvariant(this._domRef, sortKey);
    ctx.startSort(sortKey, this._domRef as HTMLElement);
  };

  /**
   * Mouse event handler to start sort.
   */
  private _handleMouseDown = (ctx: SortableContextType, evt: MouseEvent) => {
    if (this.props.disabled) return;
    if (evt.button === 0) {
      this._startSort(ctx);
    }
  };

  /**
   * Touch event handler to start sort.
   */
  private _handleTouchStart = (ctx: SortableContextType, evt: TouchEvent) => {
    if (evt.touches.length === 1) {
      this._startSort(ctx);
    }
  };

  /* ---------------------------------------------------------------------------
   * Animation Event Handlers
   * ------------------------------------------------------------------------ */

  /**
   * Handles the start of the FLIP animation.
   */
  private _handleAnimationPlay = () => {
    if (this._ctx && this._ctx.sortingKey != null) {
      this.setState({ animating: true });
    }
  };

  /**
   * Handles the end of the FLIP animation.
   */
  private _handleAnimationFinish = () => {
    if (this.state.animating === true) {
      this.setState({ animating: false });
    }
  };

  /* ---------------------------------------------------------------------------
   * Miscellaneous Private Methods
   * ------------------------------------------------------------------------ */

  /**
   * Sets the DOM element ref.
   */
  private _setDomRef = (el: HTMLElement | null) => {
    this._domRef = el;
  };

  /**
   * Checks if this item is currently being sorted.
   * @param ctx Sortable context value.
   */
  private _isSorting(ctx: SortableContextType) {
    return !!(ctx.sortingKey && ctx.sortingKey === this.props.sortKey);
  }

  /**
   * Helper to determine if this component should animate a change in position.
   * @param prevProps Previous component properties.
   */
  private _shouldAnimate(prevProps: SortableItemProps) {
    return (
      this.props.order !== prevProps.order &&
      this._domRef &&
      this._ctx &&
      !this._isSorting(this._ctx)
    );
  }
}
