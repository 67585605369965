import React, { HTMLAttributes, SFC } from 'react';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root'>(theme => ({
  root: {
    position: 'relative',
  },
}));

export interface CardMediaProps extends HTMLAttributes<HTMLDivElement> {
  domRef?: (el: HTMLElement | null) => void;
}

type Props = WithStyles<CardMediaProps, typeof styles>;

const CardMedia: SFC<Props> = ({
  className,
  classes,
  theme,
  domRef,
  ...rest
}) => <div ref={domRef} className={cx(classes.root, className)} {...rest} />;

export default withStyles(styles)(CardMedia);
