import React, { Component, ReactElement, ReactNode, cloneElement } from 'react';
import DragContext, { DragContextType } from './DragContext';

export type DragHandleProps = {
  children:
    | ReactElement<DragContextType>
    | ((props: DragContextType) => ReactNode);
};

type Props = DragHandleProps;

export default class DragHandle extends Component<Props> {
  render() {
    const { children } = this.props;

    if (children) {
      return (
        <DragContext.Consumer>
          {ctx => this._renderChildren(ctx)}
        </DragContext.Consumer>
      );
    }
    return null;
  }

  /**
   *
   */
  private _renderChildren = (ctx: DragContextType): ReactNode => {
    const { children } = this.props;
    if (typeof children === 'function') {
      return children(ctx);
    } else {
      return cloneElement(children as ReactElement<DragContextType>, ctx);
    }
  };
}
