import { Quill } from 'quill';
import React, { SFC } from 'react';
import IconMenuItem from '../IconMenuItem';
import Menu from '../Menu';
import ToolbarFlyout from '../ToolbarFlyout';

import {
  FormatAlignCenter,
  FormatAlignJustify,
  FormatAlignLeft,
  FormatAlignRight,
} from '@robotsnacks/icons';

type AlignFlyoutProps = {
  quill: Quill;
};

type FlyoutProps = {
  quill: Quill;
};

const Flyout: SFC<FlyoutProps> = ({ quill }) => (
  <Menu>
    <IconMenuItem onClick={() => quill.format('align', undefined, 'user')}>
      <FormatAlignLeft />
    </IconMenuItem>
    <IconMenuItem onClick={() => quill.format('align', 'center', 'user')}>
      <FormatAlignCenter />
    </IconMenuItem>
    <IconMenuItem onClick={() => quill.format('align', 'right', 'user')}>
      <FormatAlignRight />
    </IconMenuItem>
    <IconMenuItem onClick={() => quill.format('align', 'justify', 'user')}>
      <FormatAlignJustify />
    </IconMenuItem>
  </Menu>
);

const getCurrentAlignment = (quill: Quill) => {
  const selection = quill.getSelection();
  return (selection && quill.getFormat().align) || 'left';
};

const getAlignmentIcon = (alignment: string) => {
  switch (alignment) {
    case 'center':
      return <FormatAlignCenter />;
    case 'right':
      return <FormatAlignRight />;
    case 'justify':
      return <FormatAlignJustify />;
    case 'left':
    default:
      return <FormatAlignLeft />;
  }
};

const AlignmentFlyout: SFC<AlignFlyoutProps> = ({ quill }) => (
  <ToolbarFlyout flyout={<Flyout quill={quill} />}>
    {getAlignmentIcon(getCurrentAlignment(quill))}
  </ToolbarFlyout>
);

export default AlignmentFlyout;
