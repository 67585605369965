import { noop, partial } from 'lodash';
import React, { PureComponent, ReactNode } from 'react';
import GridList from '../GridList';
import GridListSection from '../GridListSection';
import { GroupedListPickerOptionsType } from '../ListPicker';
import Picker, { EmptyPicker } from '../Picker';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

import GridListPickerOption, {
  GridListPickerOptionsType,
} from '../GridListPickerOption';

const styles = createStyles<'root' | 'sectionTitle'>(theme => ({
  root: {
    ...theme.font('control'),
  },
  sectionTitle: {
    marginBottom: 4,
    marginTop: 4,
  },
}));

export type GroupedGridListPickerOptionsType<V> = GroupedListPickerOptionsType<
  V
>;

export interface GridListPickerProps<V> {
  className?: string;
  emptyChildren?: ReactNode;
  footer?: ReactNode;
  header?: ReactNode;
  onSelect: (value: any) => void;
  options: GridListPickerOptionsType<V>[] | GroupedGridListPickerOptionsType<V>;
  sectionClassName?: string;
}

type Props<V> = WithStyles<GridListPickerProps<V>, typeof styles>;

const defaultProps = Object.freeze({
  options: [],
  onSelect: noop,
});

class GridListPicker<V> extends PureComponent<Props<V>> {
  static readonly defaultProps = defaultProps;

  render() {
    const {
      classes,
      className,
      emptyChildren,
      footer,
      header,
      options,
    } = this.props;
    return (
      <Picker
        className={cx(classes.root, className)}
        footer={footer}
        header={header}
      >
        {options.length === 0 && <EmptyPicker>{emptyChildren}</EmptyPicker>}
        {options.length > 0 && (
          <GridList>{this._renderOptions(options)}</GridList>
        )}
      </Picker>
    );
  }

  private _renderOptions = (
    options:
      | GridListPickerOptionsType<V>[]
      | GroupedGridListPickerOptionsType<V>,
  ): ReactNode => {
    const { classes, sectionClassName } = this.props;
    if (options.length === 0) return null;
    if (options[0] instanceof Array) {
      return (options as GroupedGridListPickerOptionsType<V>).map(
        ([title, sectionOptions]) => (
          <GridListSection
            className={sectionClassName}
            key={typeof title === 'string' ? title : title.key}
            title={typeof title === 'string' ? title : title.title}
            titleClassName={classes.sectionTitle}
            sticky
          >
            {this._renderOptions(sectionOptions)}
          </GridListSection>
        ),
      );
    } else {
      return (options as GridListPickerOptionsType<V>[]).map(option => (
        <GridListPickerOption
          {...option}
          key={option.key}
          onClick={partial(this.props.onSelect, option.value)}
        />
      ));
    }
  };
}

export default withStyles(styles)(GridListPicker);
