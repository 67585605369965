import { without } from 'lodash';
import { cx, hypotenuse } from '../utils';

import { WithStyles, createStyles, keyframes, withStyles } from '../styles';

import React, {
  CSSProperties,
  MouseEvent,
  PureComponent,
  TouchEvent,
  createRef,
} from 'react';

const BLOT_EASING = 'cubic-bezier(0.42, 0, 0.58, 1)';

const styles = createStyles<'root' | 'blot' | 'in' | 'out'>(theme => {
  const blotInAnimation = keyframes({
    '0%': { opacity: 0 },
    '50%': { opacity: 1 },
  });

  const blotOutAnimation = keyframes({
    '0%': { opacity: 1 },
    '100%': { opacity: 0 },
  });

  return {
    root: {
      bottom: 0,
      borderRadius: 'inherit',
      boxSizing: 'border-box',
      fontSize: 0,
      left: 0,
      opacity: 0.2,
      overflow: 'hidden',
      position: 'absolute',
      right: 0,
      top: 0,
      transform: 'translate3d(0, 0, 0) perspective(1px)',
      '& svg': {
        pointerEvents: 'none',
      },
    },
    blot: {
      fill: 'currentColor',
      opacity: 0,
      pointerEvents: 'none',
      transformOrigin: 'top left',
      '&$in': {
        animation: `${blotInAnimation} 1s`,
        opacity: 1,
      },
      '&$out': {
        animation: `${blotOutAnimation} 1s`,
        opacity: 0,
      },
    },
    in: {},
    out: {},
  };
});

const INK_TIME_ANIM_IN_TIME = 500;
const INK_ANIM_OUT_TIME = 5500;

export type InkProps = {
  center?: boolean;
  children?: React.ReactNode;
  className?: string;
  contain?: boolean;
  disabled?: boolean;
  style?: CSSProperties;
};

type Props = WithStyles<InkProps, typeof styles> & typeof defaultProps;

const defaultProps = Object.freeze({
  duration: INK_TIME_ANIM_IN_TIME,
});

const initialState = Object.freeze({});

class Ink extends PureComponent<Props> {
  static defaultProps = defaultProps;
  state = initialState;

  private _blots: SVGCircleElement[] = [];
  private _container = createRef<HTMLSpanElement>();
  private _svg = createRef<SVGSVGElement>();
  private _timerIds: any[] = [];

  componentWillUnmount() {
    this._timerIds.forEach(t => {
      if (t) clearTimeout(t as number);
    });
  }

  render() {
    const { classes, className, style } = this.props;
    return (
      <span
        role={'none'}
        className={cx(classes.root, className)}
        ref={this._container}
        onTouchStart={this._handleTouchStart}
        onTouchEnd={this._clearBlots}
        onMouseDown={this._handleMouseDown}
        onMouseUp={this._clearBlots}
        onMouseLeave={this._clearBlots}
        style={style}
      >
        <svg height="100%" width="100%" ref={this._svg} />
      </span>
    );
  }

  private _addBlot(clientX: number, clientY: number) {
    const { center, classes, contain, disabled } = this.props;
    const containerElement = this._container.current;
    const svgElement = this._svg.current;

    if (disabled || !containerElement || !svgElement) return;

    const containerRect = containerElement.getBoundingClientRect();
    const { left, top } = containerElement.getBoundingClientRect();

    let x = clientX - left;
    let y = clientY - top;

    if (center) {
      x = containerRect.width / 2;
      y = containerRect.height / 2;
    }

    const width = Math.max(containerRect.width - x, x);
    const height = Math.max(containerRect.height - y, y);
    const maxRadius = contain
      ? Math.min(width, height)
      : hypotenuse(width, height);

    const blot: SVGCircleElement = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'circle',
    );

    blot.classList.add(classes.blot);
    blot.setAttribute('cx', `${x}`);
    blot.setAttribute('cy', `${y}`);
    svgElement.appendChild(blot);

    blot.animate([{ r: '0' } as any, { r: `${maxRadius}` } as any], {
      duration: INK_TIME_ANIM_IN_TIME,
      easing: BLOT_EASING,
      fill: 'forwards',
    });

    setTimeout(() => blot.classList.add(classes.blot, classes.in), 10);
    this._blots.push(blot);
  }

  /**
   * Animates-out all ink blots.
   */
  private _clearBlots = () => {
    const { classes } = this.props;
    const blotList = this._blots;
    blotList.forEach(blot => {
      blot.classList.remove(classes.in);
      blot.classList.add(classes.out);
    });
    const timer = setTimeout(() => {
      const svgElement = this._svg.current;
      if (!svgElement) return;
      blotList.forEach(blot => svgElement.removeChild(blot));
      this._timerIds = without(this._timerIds, timer);
    }, INK_ANIM_OUT_TIME);
    this._blots = [];
    this._timerIds = this._timerIds.concat(timer);
  };

  /**
   * Handles a touchstart event by adding an ink blot at the pressed
   * coordinates.
   * @param e Touch event.
   */
  private _handleTouchStart = (e: TouchEvent) => {
    if (e.changedTouches.length > 0) {
      for (let i = 0; i < e.changedTouches.length; i += 1) {
        const { clientX, clientY } = e.changedTouches[i];
        this._addBlot(clientX, clientY);
      }
    }
  };

  /**
   * Handle a mousedown event by adding an ink blot at the clicked coordinates.
   * @param e Mouse down event.
   */
  private _handleMouseDown = (e: MouseEvent) => {
    const { clientX, clientY } = e;
    this._addBlot(clientX, clientY);
  };
}

export default withStyles(styles)(Ink);
