import { Theme, TypographyVariant } from '../theme';

export const vr = (theme: Theme, variant: TypographyVariant): string => {
  const font = theme.typography[variant];
  return `${theme.typography.baseline * font.vr}rem`;
};

export const vrCustomProperty = (
  theme: Theme,
  variant: TypographyVariant,
): string => {
  const baselineVar = theme.vars.typography.baseline;
  const multipleVar = theme.vars.typography[variant].vr;
  return `calc(var(${baselineVar}) * var(${multipleVar}))`;
};
