import { identity, noop } from 'lodash';
import React, { ChangeEvent, Component } from 'react';
import FadeTransition from '../FadeTransition';
import PulseProgress from '../PulseProgress';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';
import InputShell, { InputShellProps } from './InputShell';

const styles = createStyles<'root' | 'loading' | 'loader'>(() => ({
  root: {
    '& input': {
      background: 'transparent',
      border: 0,
      boxSizing: 'border-box',
      display: 'block',
      flex: 1,
      font: 'inherit',
      outline: 0,
      position: 'relative',
      transition: 'padding-right 200ms',
      width: '100%',
    },
  },
  loading: {
    '& input': {
      paddingRight: '1.3rem',
    },
  },
  loader: {
    position: 'absolute',
    right: 0,
    bottom: 4,
  },
}));

type TextProcessor = (value: string) => string;

export interface InputProps
  extends Pick<
    InputShellProps,
    Exclude<keyof InputShellProps, 'focused' | 'value' | 'onChange'>
  > {
  autoComplete?:
    | 'on'
    | 'off'
    | 'name'
    | 'honorific-prefix'
    | 'given-name'
    | 'additional-name'
    | 'family-name'
    | 'honorific-suffix'
    | 'nickname'
    | 'email'
    | 'username'
    | 'new-password'
    | 'current-password'
    | 'organization-title'
    | 'organization'
    | 'street-address'
    | 'address-line1'
    | 'address-line2'
    | 'address-line3'
    | 'address-line4'
    | 'country'
    | 'country-name'
    | 'postal-code'
    | 'cc-name'
    | 'cc-given-name'
    | 'cc-additional-name'
    | 'cc-family-name'
    | 'cc-number'
    | 'cc-exp'
    | 'cc-exp-month'
    | 'cc-exp-year'
    | 'cc-csc'
    | 'cc-type'
    | 'transaction-currency'
    | 'transaction-amount'
    | 'language'
    | 'bday'
    | 'bday-day'
    | 'bday-month'
    | 'bday-year'
    | 'sex'
    | 'tel'
    | 'tel-country-code'
    | 'tel-national'
    | 'tel-area-code'
    | 'tel-local'
    | 'tel-extension'
    | 'email'
    | 'impp'
    | 'url'
    | 'photo';
  inputMode?: 'none' | 'text' | 'decimal' | 'numeric';
  loading?: boolean;
  onChange: (value: string) => void;
  placeholder?: string;
  processor: TextProcessor;
  readonly?: boolean;
  type?: 'tel' | 'text' | 'url';
  value: string;
}

type Props = WithStyles<InputProps, typeof styles>;

type State = {
  focused?: boolean;
};

const defaultProps = Object.freeze({
  processor: identity,
  onChange: noop,
  value: '',
});

const initialState = Object.freeze({ focused: false });

class Input extends Component<Props, State> {
  static defaultProps = defaultProps;
  state = initialState;

  render() {
    const {
      autoComplete,
      classes,
      className,
      description,
      disabled,
      error,
      icon,
      inputMode,
      label,
      loading,
      onChange,
      placeholder,
      processor,
      readonly,
      required,
      type,
      value,
      ...rest
    } = this.props;

    return (
      <InputShell
        className={cx(classes.root, loading && classes.loading, className)}
        description={description}
        disabled={disabled}
        focused={this.state.focused}
        icon={icon}
        label={label}
        required={required}
        value={!!value}
        error={error}
        {...rest}
      >
        <input
          aria-invalid={!!error}
          autoComplete={autoComplete}
          disabled={disabled}
          inputMode={inputMode}
          onBlur={this._handleBlur}
          onChange={onChange ? this._handleChange : undefined}
          onFocus={this._handleFocus}
          placeholder={placeholder}
          readOnly={readonly}
          required={required}
          type={type}
          value={processor(value)}
        />
        <FadeTransition in={loading} mountOnEnter unmountOnExit>
          {props => <PulseProgress {...props} className={classes.loader} />}
        </FadeTransition>
      </InputShell>
    );
  }

  private _handleBlur = () => {
    this.setState({ focused: false });
  };

  private _handleFocus = () => {
    this.setState({ focused: true });
  };

  private _handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.props.onChange(this.props.processor(e.target.value));
  };
}

export default withStyles(styles)(Input);
