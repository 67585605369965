import React, { HTMLAttributes, ReactNode, SFC } from 'react';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root' | 'title' | 'sticky'>(theme => ({
  root: {
    position: 'relative',
    '&:first-child $title': {
      marginTop: 0,
    },
  },
  title: {
    background: theme.color('grey', '50'),
    boxShadow: `0 1px 2px ${theme.color('grey', '100')}`,
    boxSizing: 'border-box',
    color: theme.color('text.secondary'),
    fontSize: '0.8em',
    letterSpacing: 0.5,
    lineHeight: 1.7,
    paddingTop: '0.06em',
    textAlign: 'center',
    textTransform: 'uppercase',
    top: 0,
    userSelect: 'none',
    width: '100%',
    marginBottom: 4,
  },
  sticky: {
    position: 'sticky',
    zIndex: 1,
  },
}));

export interface ListSectionProps
  extends Pick<
    HTMLAttributes<HTMLDivElement>,
    Exclude<keyof HTMLAttributes<HTMLDivElement>, 'title'>
  > {
  sticky?: boolean;
  title?: ReactNode;
  titleClassName?: string;
}

type Props = WithStyles<ListSectionProps, typeof styles>;

const ListSection: SFC<Props> = ({
  children,
  classes,
  className,
  sticky,
  theme,
  title,
  titleClassName,
  ...props
}) => (
  <div className={cx(classes.root, className)} {...props}>
    {title && (
      <div
        aria-hidden={true}
        className={cx(classes.title, titleClassName, sticky && classes.sticky)}
      >
        {title}
      </div>
    )}
    {children}
  </div>
);

export default withStyles(styles)(ListSection);
