import React, { CSSProperties, SFC } from 'react';
import { MagnetAnchor } from '../Magnet';
import MagnetArrowBox from '../MagnetArrowBox';

type Props = {
  anchor?: MagnetAnchor;
  className?: string;
  domRef?: (el: HTMLElement | null) => void;
  style?: CSSProperties;
};

const PopupWrapper: SFC<Props> = ({ children, className, ...rest }) => (
  <MagnetArrowBox swap slide arrowBoxClassName={className} {...rest}>
    {children}
  </MagnetArrowBox>
);

export default PopupWrapper;
