const PHI = 1.61803398875;

const ratios = {
  'minor second': 16 / 15,
  'major second': 9 / 8,
  'minor third': 6 / 5,
  'major third': 4 / 3,
  'augmented fourth': Math.sqrt(2),
  'perfect fifth': 3 / 2,
  'minor sixth': 8 / 5,
  golden: PHI,
  phi: PHI,
  'major sixth': 5 / 3,
  'minor seventh': 16 / 9,
  'major seventh': 15 / 8,
  octave: 2,
  'major tenth': 5 / 2,
  'major eleventh': 8 / 3,
  'major twelfth': 3,
  'double octave': 4,
};

export type ModularScale = keyof typeof ratios;

const computeRatio = (ratio: ModularScale | number): number => {
  if (typeof ratio === 'number') {
    return ratio;
  } else if (ratios[ratio]) {
    return ratios[ratio];
  } else {
    throw new Error(
      `Invalid modular scale: "${ratio}" - Must be number of scale name.`,
    );
  }
};

export const modularScale = (
  ratio: ModularScale | number,
): ((step: number) => number) => {
  const r = computeRatio(ratio);
  return (step: number): number => Math.pow(r, step);
};
