import { noop } from 'lodash';
import { Component, ReactElement, ReactNode, cloneElement } from 'react';

export type TransitionState =
  | 'enter'
  | 'enterActive'
  | 'enterDone'
  | 'exit'
  | 'exitActive'
  | 'exitDone';

export interface TransitionProps {
  appear?: boolean;
  children:
    | ReactElement<TransitionChildProps>
    | ((transitionState: TransitionState, end: () => void) => ReactNode);
  in?: boolean;
  mountOnEnter?: boolean;
  onAppear?: () => void;
  onAppearActive?: () => void;
  onBeforeEnter?: () => void;
  onBeforeExit?: () => void;
  onEnter?: () => void;
  onEnterActive?: () => void;
  onEnterDone?: () => void;
  onExit?: () => void;
  onExitActive?: () => void;
  onExitDone?: () => void;
  onTransition?: () => void;
  timeout?: number | false;
  unmountOnExit?: boolean;
}

export type TransitionChildProps = {
  transitionState: TransitionState;
  endTransition: () => void;
};

type Props = TransitionProps & typeof defaultProps;

type State = {
  mounted?: boolean;
  transitionState?: TransitionState;
};

const defaultProps = Object.freeze({
  appear: false,
  in: false,
  mountOnEnter: false,
  onAppear: noop,
  onAppearActive: noop,
  onBeforeEnter: noop,
  onBeforeExit: noop,
  onEnter: noop,
  onEnterActive: noop,
  onEnterDone: noop,
  onExit: noop,
  onExitActive: noop,
  onExitDone: noop,
  onTransition: noop,
  timeout: false as number | false,
  unmountOnExit: false,
});

const getInitialState = (props: Props): State => ({
  mounted: !props.mountOnEnter || props.in,
  transitionState: props.in ? 'enterDone' : 'exitDone',
});

export default class Transition extends Component<Props, State> {
  static defaultProps = defaultProps;
  state: State = getInitialState(this.props);

  private _timerId: any = null;

  componentDidMount() {
    if (this.props.appear && this.props.in) {
      this._handleEnter();
    }
  }

  componentDidUpdate(prev: Props) {
    const { in: _in } = this.props;
    if (prev.in === false && _in === true) this._handleEnter();
    if (prev.in === true && _in === false) this._handleExit();
  }

  componentWillUnmount() {
    this._clearTimer();
  }

  render() {
    const { children } = this.props;
    const { mounted, transitionState } = this.state;
    if (!mounted) return null;
    if (typeof children === 'function') {
      return children(transitionState || 'exitDone', this._handleTransitionEnd);
    } else {
      return cloneElement(children as ReactElement<TransitionChildProps>, {
        transitionState,
        endTransition: this._handleTransitionEnd,
      });
    }
  }

  private _handleEnter = () => {
    const { mounted } = this.state;
    this._clearTimer();
    const maybeAfterMount = () => {
      const { transitionState = '' } = this.state;
      if (transitionState.indexOf('enter') !== -1) return;
      const { onBeforeEnter, onTransition } = this.props;
      onTransition();
      onBeforeEnter();
      this.setState({ transitionState: 'enter' }, () => {
        onTransition();
        this.props.onEnter();
        this.setState({ transitionState: 'enterActive' }, () => {
          onTransition();
          this.props.onEnterActive();
        });
      });
    };
    if (!mounted) {
      this.setState({ mounted: true }, maybeAfterMount);
    } else {
      maybeAfterMount();
    }
  };

  private _handleExit = () => {
    const { transitionState = '' } = this.state;
    if (transitionState.indexOf('exit') !== -1) return;
    const { onBeforeExit, onTransition } = this.props;
    this._clearTimer();
    onBeforeExit();
    onTransition();
    this.setState({ transitionState: 'exit' }, () => {
      this.props.onExit();
      this.setState({ transitionState: 'exitActive' }, this.props.onExitActive);
    });
  };

  private _handleTransitionEnd = () => {
    this.setState(
      {
        transitionState:
          this.state.transitionState === 'enterActive'
            ? 'enterDone'
            : 'exitDone',
      },
      () => {
        const { unmountOnExit } = this.props;
        this._clearTimer();
        const maybeAfterUnmount = () => {
          const { onEnterDone, onExitDone, onTransition } = this.props;
          onTransition();
          if (this.state.transitionState === 'enterDone') {
            onEnterDone();
          } else {
            onExitDone();
          }
        };

        if (unmountOnExit && this.state.transitionState === 'exitDone') {
          this.setState({ mounted: false }, maybeAfterUnmount);
        } else {
          maybeAfterUnmount();
        }
      },
    );
  };

  private _setTimer = () => {
    const { timeout } = this.props;
    this._clearTimer();
    if (typeof timeout === 'number') {
      this._timerId = setTimeout(this._handleTransitionEnd, timeout);
    }
  };

  private _clearTimer = () => {
    const t = this._timerId;
    if (t) clearTimeout(t as any);
  };
}
