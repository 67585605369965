import { omit } from 'lodash';
import React, { SFC } from 'react';
import CSSTransition, { CSSTransitionProps } from '../CSSTransition';
import { TransitionState } from '../Transition';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root' | TransitionState>(() => ({
  root: { transition: 'opacity 300ms' },
  enter: { opacity: 0 },
  enterActive: { opacity: 1 },
  enterDone: { opacity: 1 },
  exit: { opacity: 1 },
  exitActive: { opacity: 0 },
  exitDone: { opacity: 0 },
}));

export type FlyoutTransitionProps = Pick<
  CSSTransitionProps,
  Exclude<keyof CSSTransitionProps, 'transitionClassNames'>
>;

type Props = WithStyles<FlyoutTransitionProps, typeof styles>;

const FlyoutTransition: SFC<Props> = ({ classes, className, ...rest }) => (
  <CSSTransition
    {...rest}
    className={cx(classes.root, className)}
    transitionClassNames={omit(classes, 'root')}
  />
);

export default withStyles(styles)(FlyoutTransition);
