import { forEach, isArray, isPlainObject, reduce } from 'lodash';

type NameMap = { [className: string]: boolean | null | undefined };
type Name = false | null | string | undefined;
type InputName = Name | NameMap | Array<Name | NameMap>;

/**
 * Internal recursive function which determines which class names to apply
 * @param map Mutable map of the form [class name] => boolean
 * @param input Input to the classNames() utility function.
 */
const applyClassNames = (map: NameMap, ...input: InputName[]): string => {
  for (let i = 0; i < input.length; i++) {
    const name = input[i];
    if (typeof name === 'string') {
      map[name] = true;
    } else if (isArray(name)) {
      applyClassNames(map, ...name);
    } else if (isPlainObject(name)) {
      forEach(name as object, (v, k) => {
        map[k] = v;
      });
    }
  }
  return reduce(
    map,
    (acc: string[], v, k) => {
      if (v) return [...acc, k];
      return acc;
    },
    [],
  )
    .map(c => c.replace(/\s+/g, ' ').trim())
    .sort()
    .join(' ');
};

/**
 * Utility function for applying class names.
 * @param input Class name string(s), arrays, or maps.
 */
export default (...input: InputName[]): string => applyClassNames({}, ...input);
