import { ActionUndo } from '@robotsnacks/icons';
import { Quill } from 'quill';
import React, { SFC } from 'react';
import ToolbarButton from '../ToolbarButton';

export type UndoButtonProps = {
  quill: Quill;
};

const UndoButton: SFC<UndoButtonProps> = ({ quill }) => (
  <ToolbarButton
    onMouseDown={e => {
      e.preventDefault();
      (quill as any).history.undo();
    }}
  >
    <ActionUndo />
  </ToolbarButton>
);

export default UndoButton;
