import { Normalize } from './normalize';
import React from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { Theme } from '../theme';
import { ThemeVariables } from './theme-variables';

export interface ThemeProviderProps {
  theme: Theme;
  scope?: string;
}

export const ThemeProvider: React.FunctionComponent<ThemeProviderProps> = ({
  children,
  theme,
  scope = 'body',
  ...rest
}): React.ReactElement => {
  return (
    <StyledThemeProvider theme={theme}>
      <React.Fragment>
        <Normalize />
        <ThemeVariables scope={scope} />
        {children}
      </React.Fragment>
    </StyledThemeProvider>
  );
};
