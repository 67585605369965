import React, { HTMLAttributes, SFC } from 'react';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root'>(() => ({
  root: {
    lineHeight: 2,
    padding: '0 0.5em',
  },
}));

export interface ListItemProps extends HTMLAttributes<HTMLDivElement> {}

type Props = WithStyles<ListItemProps, typeof styles>;

const ListItem: SFC<Props> = ({ classes, className, theme, ...props }) => (
  <div role="listitem" className={cx(classes.root, className)} {...props} />
);

export default withStyles(styles)(ListItem) as SFC<ListItemProps>;
