import React, { HTMLAttributes, SFC } from 'react';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root'>(theme => ({
  root: {
    ...theme.font('control'),
    textAlign: 'left',
  },
}));

export interface ListProps extends HTMLAttributes<HTMLDivElement> {}

type Props = WithStyles<ListProps, typeof styles>;

const List: SFC<Props> = ({ classes, className, theme, ...props }) => (
  <div role="list" className={cx(classes.root, className)} {...props} />
);

export default withStyles(styles)(List);
