import React, { ComponentType, PureComponent, ReactNode } from 'react';
import List from '../List';
import ListPickerOption, { ListPickerOptionType } from '../ListPickerOption';
import ListSection from '../ListSection';
import Picker, { EmptyPicker } from '../Picker';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root'>(theme => ({
  root: {
    ...theme.font('control'),
  },
}));

export type GroupedListPickerOptionsType<V = {}> = [
  string | { title: ReactNode; key: string },
  ListPickerOptionType<V>[],
][];

export interface ListPickerProps<V> {
  className?: string;
  emptyChildren?: ReactNode;
  footer?: ReactNode;
  header?: ReactNode;
  onSelect?: (value: V) => void;
  options?: ListPickerOptionType[] | GroupedListPickerOptionsType;
  sectionClassName?: string;
  onScroll?: React.UIEventHandler<HTMLElement>;
}

type Props<V> = WithStyles<ListPickerProps<V>, typeof styles> &
  typeof defaultProps;

const defaultProps = Object.freeze({
  options: [],
});

class ListPicker extends PureComponent<Props<any>> {
  static readonly defaultProps = defaultProps;

  render() {
    const {
      classes,
      className,
      emptyChildren,
      footer,
      header,
      options,
      onScroll,
    } = this.props;
    return (
      <Picker
        className={cx(classes.root, className)}
        footer={footer}
        header={header}
        onScroll={onScroll}
      >
        {options.length === 0 && <EmptyPicker>{emptyChildren}</EmptyPicker>}
        {options.length > 0 && <List>{this._renderOptions(options)}</List>}
      </Picker>
    );
  }

  private _renderOptions = (
    options: ListPickerOptionType[] | GroupedListPickerOptionsType,
  ): ReactNode => {
    const { classes, sectionClassName } = this.props;
    if (options.length === 0) return null;
    if (options[0] instanceof Array) {
      return (options as GroupedListPickerOptionsType).map(
        ([title, sectionOptions]) => (
          <ListSection
            className={sectionClassName}
            key={typeof title === 'string' ? title : title.key}
            title={typeof title === 'string' ? title : title.title}
            sticky
          >
            {this._renderOptions(sectionOptions)}
          </ListSection>
        ),
      );
    } else {
      return (options as ListPickerOptionType[]).map(option => (
        <ListPickerOption
          {...option}
          key={option.key}
          onClick={() => {
            if (this.props.onSelect) {
              this.props.onSelect(option.value);
            }
          }}
        />
      ));
    }
  };
}

export default withStyles(styles)(ListPicker) as ComponentType<
  ListPickerProps<any>
>;
