import { Breakpoint, Theme, TypographyVariant } from '../theme';

const buildDivisorString = (divisor?: number): string =>
  divisor ? ` / ${divisor}` : '';

export const rvr = (
  theme: Theme,
  variant: TypographyVariant,
  breakpoint: Breakpoint,
  variantOverride?: TypographyVariant,
  divisor?: number,
): string => {
  const baseline = theme.typography.baseline;

  const multiple =
    theme.typography[variantOverride || variant || 'body'].rvr[breakpoint];

  if (divisor) {
    const d = buildDivisorString(divisor);
    return `calc(${baseline * multiple} * 1rem${d})`;
  } else {
    return `${baseline * multiple}rem`;
  }
};

export const rvrCustomProperty = (
  theme: Theme,
  variant: TypographyVariant,
  breakpoint: Breakpoint,
  variantOverride?: TypographyVariant,
  divisor?: number,
): string => {
  const baseline = theme.typography.baseline;
  const baselineVar = theme.vars.typography.baseline;

  const multiple =
    theme.typography[variantOverride || variant || 'body'].rvr[breakpoint];

  const multipleVar =
    theme.vars.typography[variantOverride || variant || 'body'].rvr[breakpoint];

  const d = buildDivisorString(divisor);

  return `calc(var(${baselineVar}, ${baseline}) * var(${multipleVar}, ${multiple}) * 1rem${d})`;
};
