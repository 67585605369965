import React, { SFC } from 'react';
import { cx } from '../utils';

import { WithStyles, createStyles, keyframes, withStyles } from '../styles';

const styles = createStyles<
  'error' | 'primary' | 'root' | 'secondary' | 'success' | 'warning'
>(theme => {
  const animation = keyframes({
    '0%, 10%, 70%, 100%': {
      transform: 'scaleY(1)',
      opacity: 0.3,
    },
    '30%': {
      transform: 'scaleY(3)',
      opacity: 0.8,
    },
  });

  return {
    root: {
      display: 'inline-block',
      height: '1em',
      position: 'relative',
      width: '1em',
      transform: 'rotate(0)',
      '& span': {
        animation: `${animation} ease-in 1.6s infinite`,
        background: theme.color('text.body'),
        display: 'inline-block',
        height: 3,
        opacity: 0.3,
        position: 'absolute',
        bottom: 6,
        width: 2,
      },
      '& span:nth-child(1)': {
        animationDelay: '0ms',
        left: 0,
      },
      '& span:nth-child(2)': {
        animationDelay: '0.2s',
        left: 4,
      },
      '& span:nth-child(3)': {
        animationDelay: '0.4s',
        left: 8,
      },
      '& span:nth-child(4)': {
        animationDelay: '0.6s',
        left: 12,
      },
    },
    primary: {
      '& span': {
        background: theme.color('primary'),
      },
    },
    secondary: {
      '& span': {
        background: theme.color('secondary'),
      },
    },
    error: {
      '& span': {
        background: theme.color('error'),
      },
    },
    success: {
      '& span': {
        background: theme.color('success'),
      },
    },
    warning: {
      '& span': {
        background: theme.color('warning'),
      },
    },
  };
});

export type PulseProgressProps = {
  children?: never;
  className?: string;
  domRef?: (el: HTMLSpanElement | null) => void;
  size?: number;
  type?: 'error' | 'primary' | 'secondary' | 'success' | 'warning';
};

type Props = WithStyles<PulseProgressProps, typeof styles>;

const PulseProgress: SFC<Props> = ({
  classes,
  className,
  domRef,
  size = 14,
  type,
}) => {
  const h = Math.round(size * 0.21);
  const w = Math.min(Math.round(size * 0.15), 2);

  const spx = `${size}px`;
  const hpx = `${h}px`;
  const wpx = `${w}px`;
  const bpx = `${h * 2}px`;

  return (
    <span
      className={cx(classes.root, type && classes[type], className)}
      style={{ height: spx, width: spx }}
      ref={domRef}
    >
      <span style={{ height: hpx, width: wpx, left: 0, bottom: bpx }} />
      <span
        style={{ height: hpx, width: wpx, left: `${2 * w}px`, bottom: bpx }}
      />
      <span
        style={{ height: hpx, width: wpx, left: `${4 * w}px`, bottom: bpx }}
      />
      <span
        style={{ height: hpx, width: wpx, left: `${6 * w}px`, bottom: bpx }}
      />
    </span>
  );
};

export default withStyles(styles)(PulseProgress);
