import { Quill as TQuill } from 'quill';
declare const Quill: typeof TQuill;

export default () => {
  const BaseLink = Quill.import('formats/link');

  return class LinkFormat extends BaseLink {
    public static create({
      target = '_self',
      href,
      noFollow,
      placeholder,
    }: any = {}) {
      const node: HTMLElement = super.create(href);

      node.setAttribute(
        'rel',
        noFollow ? 'nofollow noopener noreferrer' : 'noopener noreferrer',
      );

      if (placeholder) {
        node.setAttribute('href', '');
        node.setAttribute('target', '');
        node.classList.add('text--force-select');
      } else {
        node.classList.remove('text--force-select');
        node.setAttribute('href', href ? this.sanitize(href) : '');
        node.setAttribute(
          'target',
          // Always open in a new window if we're inside an iframe.
          // TODO: in the future, we need to be smarter about this.... sometimes
          // we may want it to open in the same window! Actually, we should
          // probably just check the domain. If it's different, open it in a new
          // window. We could also show a warning to the user to let them know
          // that in "admin" mode, we're opening it in a new window.
          window.parent !== window ? '_blank' : target,
        );
      }

      return node;
    }

    public static formats(domNode: any) {
      const href = domNode.getAttribute('href') || '';
      return {
        href,
        target: domNode.getAttribute('target'),
        noFollow: (domNode.getAttribute('rel') || '').includes('nofollow'),
      };
    }

    public format(name: any, value: any) {
      if (name !== this.statics.blotName || !value) {
        super.format(name, value);
      } else {
        if (value.placeholder) {
          this.domNode.classList.add('text--force-select');
        } else {
          this.domNode.classList.remove('text--force-select');
        }
        this.domNode.setAttribute(
          'href',
          (this.constructor as any).sanitize(value.href),
        );
        this.domNode.setAttribute(
          'rel',
          value.noFollow
            ? 'nofollow noopener noreferrer'
            : 'noopener noreferrer',
        );
      }
    }
  };
};
