import color from 'color';
import { max, reduce } from 'lodash';
import shades from './shades';

export type ColorName =
  | 'background'
  | 'background.paper'
  | 'divider'
  | 'error'
  | 'grey'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'text.body.contrast'
  | 'text.body'
  | 'text.disabled'
  | 'text.heading.contrast'
  | 'text.heading'
  | 'text.hint'
  | 'text.link.contrast'
  | 'text.link'
  | 'text.secondary'
  | 'warning';

export type ColorShade =
  | '50'
  | '100'
  | '200'
  | '300'
  | '400'
  | '500'
  | '600'
  | '700'
  | '800'
  | '900'
  | 'contrast';

export type ColorShadeMap = { [S in ColorShade]: string };

export type ColorMap = { [K in ColorName]: ColorShadeMap };

export type PartialColorMap = Partial<
  { [K in ColorName]: string | Partial<ColorShadeMap> }
>;

const defaults = {
  background: '#fefefe',
  'background.paper': 'white',
  divider: 'rgba(0, 0, 0, 0.38)',
  error: 'red',
  grey: '#888',
  primary: 'blue',
  secondary: 'purple',
  success: 'green',
  'text.body.contrast': 'rgba(255, 255, 255, 0.87)',
  'text.body': 'rgba(0, 0, 0, 0.87)',
  'text.disabled': 'rgba(0, 0, 0, 0.38)',
  'text.heading.contrast': 'rgba(255, 255, 255, 0.87)',
  'text.heading': 'rgba(0, 0, 0, 0.87)',
  'text.hint': 'rgba(0, 0, 0, 0.38)',
  'text.link.contrast': 'white',
  'text.link': 'blue',
  'text.secondary': 'rgba(0, 0, 0, 0.54)',
  warning: 'orange',
};

/**
 * Simple invariant check to ensure the passed set of colors has 10 shades.
 */
const colorsLengthInvariant = (colors: string[]) => {
  if (colors.length !== 10) {
    throw new Error('Invalid color set. Must have 10 entries.');
  }
};

/**
 * Generates a text contrast color.
 * @param background The background color.
 * @param options Set of option colors.
 */
const contrastText = (background: string, ...options: string[]) => {
  const bg = color(background);
  const ratios = options.map(opt => color(opt).contrast(bg));
  const maxRatio = max(ratios) as number;
  return options[ratios.indexOf(maxRatio)];
};

/**
 * Generates a set of color shades from a color palette.
 * @param colorSet       Input set of colors.
 * @param defaultText   Default text color.
 * @param contrastText  Contrast text color.
 */
const shadesToColorMap = (
  colors: string[],
  defaultText: string,
  contrast: string,
): ColorShadeMap => {
  colorsLengthInvariant(colors);
  return [
    '50',
    '100',
    '200',
    '300',
    '400',
    '500',
    '600',
    '700',
    '800',
    '900',
  ].reduce(
    (acc, key, index) => ({
      ...acc,
      [key]: colors[index],
    }),
    {
      contrast: contrastText(colors[6], defaultText, contrast),
    } as ColorShadeMap,
  );
};

/**
 * Generates a set of colors for a theme.
 */
export default (input: PartialColorMap = {}): ColorMap => {
  return reduce(
    [
      'background',
      'background.paper',
      'divider',
      'error',
      'grey',
      'primary',
      'secondary',
      'success',
      'text.body.contrast',
      'text.body',
      'text.disabled',
      'text.heading.contrast',
      'text.heading',
      'text.hint',
      'text.link.contrast',
      'text.link',
      'text.secondary',
      'warning',
    ] as ColorName[],
    (acc, c) => {
      const inputColor = input[c] || defaults[c];
      const shadeMap =
        typeof inputColor === 'object'
          ? inputColor
          : shadesToColorMap(
              shades(inputColor),
              defaults['text.body'],
              defaults.background,
            );
      return {
        ...acc,
        [c]: shadeMap,
      };
    },
    {} as ColorMap,
  );
};
