import React from 'react';
import GridListItem from '../GridListItem';
import Ink from '../Ink';
import { ListPickerOptionType } from '../ListPickerOption';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root'>(theme => ({
  root: {
    backgroundColor: theme.color('background'),
    cursor: 'pointer',
    position: 'relative',
    textAlign: 'center',
    transition: 'background-color 140ms',
    '&:hover': {
      backgroundColor: theme.color('grey', '50'),
    },
  },
}));

export type GridListPickerOptionsType<V> = ListPickerOptionType<V>;

export interface GridPickerOptionProps<V> extends GridListPickerOptionsType<V> {
  className?: string;
  inkClassName?: string;
  onClick?: (value: V) => void;
  labelClassName?: string;
}

type Props<V> = WithStyles<GridPickerOptionProps<V>, typeof styles>;

const ListPickerOption = ({
  classes,
  className,
  onClick,
  label,
  labelClassName,
}: Props<any>) => (
  <GridListItem className={cx(classes.root, className)} onClick={onClick}>
    <div className={labelClassName}>{label}</div>
    <Ink />
  </GridListItem>
);

export default withStyles(styles)(ListPickerOption);
