import React, { HTMLAttributes, ReactNode, SFC } from 'react';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';
import InputMessage from './InputMessage';

const styles = createStyles<
  | 'asterisk'
  | 'disabled'
  | 'error'
  | 'focused'
  | 'iconColumn'
  | 'inputColumn'
  | 'label'
  | 'root'
  | 'withLabel'
  | 'withValue'
>(theme => {
  const labelUpStyle = {
    transform: 'scale(0.8) translate(0, -1.7em) translateZ(0)',
    transition: 'color 400ms, transform 140ms, opacity 400ms;',
  };

  return {
    '@keyframes error-bg': {
      '0%, 100%': { opacity: 0.1 },
      '50%': { opacity: 0.01 },
    },
    root: {
      ...theme.font('control'),
      display: 'flex',
      margin: '1rem 0 3.5rem',
      position: 'relative',
      lineHeight: 1,
      '& input, & textarea': {
        padding: 0,
      },
      '& ::placeholder': {
        transition: 'color 240ms, opacity 240ms',
        opacity: 0.5,
      },
    },
    label: {
      color: theme.color('text.secondary'),
      fontSmoothing: 'subpixel-antialiased',
      backfaceVisibility: 'hidden',
      display: 'block',
      left: 0,
      maxWidth: '100%',
      overflow: 'hidden',
      pointerEvents: 'none',
      position: 'absolute',
      textOverflow: 'ellipsis',
      transformOrigin: 'top left',
      transition: 'color 400ms, transform 140ms',
      whiteSpace: 'nowrap',
    },
    withLabel: {
      marginTop: '3rem',
      '& ::placeholder': {
        opacity: 0,
      },
    },
    withValue: {
      '& $label': labelUpStyle,
    },
    disabled: {
      cursor: 'not-allowed',
      '& input, & textarea': {
        cursor: 'not-allowed',
      },
    },
    focused: {
      '& ::placeholder': {
        color: theme.color('text.secondary'),
        opacity: 0.8,
      },
      '& $label': {
        color: theme.color('primary'),
        ...labelUpStyle,
      },
    },
    error: {
      '& $label': {
        color: theme.color('error'),
      },
    },
    asterisk: {
      color: theme.color('error'),
      paddingLeft: '0.3em',
    },
    inputColumn: {
      borderBottom: `1px solid ${theme.color('grey', '200')}`,
      flex: 1,
      paddingBottom: 3,
      position: 'relative',
      transition: 'border-bottom-color 300ms',
      '$focused &': {
        borderBottomColor: theme.color('primary'),
      },
      '$error &': {
        borderBottomColor: theme.color('error'),
      },
      '&::before': {
        content: '""',
        display: 'block',
        height: '100%',
        left: 0,
        opacity: 0,
        pointerEvents: 'none',
        position: 'absolute',
        top: 0,
        transition: 'opacity 300ms',
        width: '100%',
      },
      '$error &::before': {
        background: theme.color('error'),
        animation: 'error-bg 3s infinite',
      },
      '$focused &::before': {
        animation: 'none',
        opacity: 0,
      },
    },
    iconColumn: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      paddingBottom: '0.6em',
      paddingRight: '0.5em',
    },
  };
});

export interface InputShellProps extends HTMLAttributes<HTMLDivElement> {
  focused?: boolean;
  value?: boolean;
  description?: ReactNode;
  disabled?: boolean;
  error?: ReactNode;
  icon?: ReactNode;
  label?: ReactNode;
  required?: boolean;
}

type Props = WithStyles<InputShellProps, typeof styles>;

const InputShell: SFC<Props> = ({
  children,
  className,
  classes,
  description,
  disabled,
  error,
  focused,
  icon,
  label,
  required,
  value,
  theme,
  ...rest
}) => {
  return (
    <div
      className={cx(
        classes.root,
        focused && classes.focused,
        !!label && classes.withLabel,
        !!error && classes.error,
        !!value && classes.withValue,
        className,
      )}
      {...rest}
    >
      {icon && <div className={classes.iconColumn}>{icon}</div>}
      <div className={classes.inputColumn}>
        {label && (
          <span className={classes.label}>
            {label}
            {required && <span className={classes.asterisk}>&#42;</span>}
          </span>
        )}
        {children}
        {!children && <span>&nbsp;</span>}
        {(error || description) && (
          <InputMessage focused={focused} error={!!error}>
            {error || description}
          </InputMessage>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(InputShell);
