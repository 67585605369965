import CSS from 'csstype';
import { reduce } from 'lodash';

export type ListName = 'unordered' | 'ordered';

export type ListMap = {
  [K in ListName]: Pick<
    CSS.Properties,
    'margin' | 'padding' | 'listStyleType' | 'listStylePosition'
  >
};

export type PartialListMap = Partial<ListMap>;

const defaults = {
  ordered: {
    margin: '1.5rem',
    padding: '0.5rem',
    listStyleType: 'decimal',
    listStylePosition: 'outside',
  },
  unordered: {
    margin: '1.5rem',
    padding: '0.5rem',
    listStyleType: 'disc',
    listStylePosition: 'outside',
  },
};

export default (input: PartialListMap = {}) =>
  reduce(
    ['ordered', 'unordered'] as ListName[],
    (acc, c) => ({
      ...acc,
      [c]: input[c] || defaults[c],
    }),
    {} as ListMap,
  );
