import { filter, head, keys, map, reduce } from 'lodash';
import { Quill } from 'quill';
import React from 'react';
import ToolbarSeparator from '../ToolbarSeparator';
import AlignFlyout from './AlignmentFlyout';
import FontFlyout from './FontFlyout';
import FormatButton from './FormatButton';
import LinkButton from './LinkButton';
import ListFormatButton from './ListFormatButton';
import RedoButton from './RedoButton';
import UndoButton from './UndoButton';

import { ToolbarInterface } from './ToolbarInterface';

export type EditorItemKey =
  | 'add-comment'
  | 'align-center'
  | 'align-justify'
  | 'align-left'
  | 'align-right'
  | 'align'
  | 'attachment'
  | 'blockquote'
  | 'bold'
  | 'clear-format'
  | 'code'
  | 'dedent'
  | 'embed'
  | 'fill-color'
  | 'font-select'
  | 'font-size'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'highlight-color'
  | 'image'
  | 'indent'
  | 'italic'
  | 'link'
  | 'ordered-list'
  | 'p'
  | 'redo'
  | 'separator'
  | 'strike-through'
  | 'text-color'
  | 'underline'
  | 'undo'
  | 'unordered-list'
  | 'video';

export type EditorItem = EditorItemKey | Partial<{ [K in EditorItemKey]: any }>;

const createEditorItem = (
  item: EditorItem,
  index: number,
  quill: Quill,
  toolbar?: ToolbarInterface,
) => {
  const key: string | EditorItemKey =
    typeof item === 'string' ? item : (head(keys(item)) as EditorItemKey);

  // FIXME: fix any cast.
  const options = typeof item === 'string' ? null : (item as any)[key];

  switch (key) {
    case 'bold':
    case 'italic':
    case 'underline':
    case 'strike-through':
    case 'blockquote':
      return <FormatButton key={key} format={key} quill={quill} />;

    case 'separator':
      return <ToolbarSeparator key={index} />;

    case 'align':
      return <AlignFlyout key={key} quill={quill} />;

    case 'undo':
      return <UndoButton key={key} quill={quill} />;

    case 'redo':
      return <RedoButton key={key} quill={quill} />;

    case 'font-select':
      return <FontFlyout key={key} quill={quill} options={options} />;

    case 'link':
      return <LinkButton key={key} quill={quill} toolbar={toolbar} />;

    case 'ordered-list':
    case 'unordered-list':
      return <ListFormatButton key={key} format={key} quill={quill} />;

    case 'add-comment':
    case 'attachment':
    case 'clear-format':
    case 'code':
    case 'dedent':
    case 'embed':
    case 'fill-color':
    case 'font-size':
    case 'highlight-color':
    case 'image':
    case 'indent':
    case 'text-color':
    case 'video':

    case 'align-center':
    case 'align-justify':
    case 'align-left':
    case 'align-right':

    case 'h1':
    case 'h2':
    case 'h3':
    case 'h4':
    case 'h5':
    case 'h6':

    case 'p':
      throw new Error('Not implemented.');
  }
};

export default (
  items: Array<EditorItem | EditorItem[]>,
  quill: Quill,
  toolbar?: ToolbarInterface,
) => {
  const reduced = reduce(
    items,
    (acc: EditorItem[], item: EditorItem | EditorItem[]) => {
      if (Array.isArray(item)) {
        return acc.concat(['separator', ...item, 'separator']);
      }
      return acc.concat([item]);
    },
    [] as any,
  );

  const filtered = filter(reduced, (item, i: any, array) => {
    // Don't show separator as first/last items.
    if (i === 0 || i === array.length - 1) return item !== 'separator';
    // Don't show consecutive separators.
    if (item === 'separator' && array[i - 1] === 'separator') return false;
    return true;
  });

  return map(filtered, (item, i) => createEditorItem(item, i, quill, toolbar));
};
