import { BoxShadowProperty } from 'csstype';
import { clamp, round } from 'lodash';
import React, { HTMLAttributes, SFC } from 'react';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx, snap } from '../utils';

export interface PaperProps extends HTMLAttributes<HTMLDivElement> {
  domRef?: (el: HTMLDivElement | null) => void;
  elevation?: number;
}

type Props = WithStyles<PaperProps, typeof styles>;

const X_DIV = 12;
const Y_DIV = 6;
const SPREAD_DIV = 5;
const BLUR_MUL = 1.8;
const BLUR_CONST = 2;

const styles = createStyles<'root', PaperProps>(theme => {
  const boxShadow = (elevation: number = 0): BoxShadowProperty => {
    if (elevation <= 0) return '';

    const x = round(elevation / X_DIV);
    const y = round(elevation / Y_DIV);
    const blur = round(elevation * BLUR_MUL + BLUR_CONST);
    const spread = round(elevation / SPREAD_DIV);
    const shadow = theme.color('grey', clamp(
      snap(elevation * 10, 100),
      100,
      900,
    ) as any);

    return `${x}px ${y}px ${blur}px ${spread}px ${shadow}`;
  };

  return {
    root: {
      background: theme.color('background.paper'),
      boxShadow: props => boxShadow(props.elevation),
    },
  };
});

export const Paper: SFC<Props> = ({
  children,
  className,
  classes,
  domRef,
  ...rest
}) => (
  <div ref={domRef} className={cx(classes.root, className)} {...rest}>
    {children}
  </div>
);

Paper.displayName = 'Paper';

export default withStyles(styles)(Paper);
