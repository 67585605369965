import { Quill } from 'quill';
import React, { SFC } from 'react';
import ToolbarButton from '../ToolbarButton';

import {
  Blockquote,
  FormatBold,
  FormatItalic,
  FormatOrderedList,
  FormatStrikeThrough,
  FormatUnderline,
  FormatUnorderedList,
} from '@robotsnacks/icons';

export type Format =
  | 'blockquote'
  | 'bold'
  | 'italic'
  | 'strike-through'
  | 'underline'
  | 'ordered-list'
  | 'unordered-list';

const iconMap: any = {
  'ordered-list': <FormatOrderedList />,
  strike: <FormatStrikeThrough />,
  'unordered-list': <FormatUnorderedList />,
  blockquote: <Blockquote />,
  bold: <FormatBold />,
  italic: <FormatItalic />,
  underline: <FormatUnderline />,
};

export type FormatButtonProps = {
  format: Format;
  quill: Quill;
};

const FormatButton: SFC<FormatButtonProps> = ({ format, quill }) => {
  const selection = quill.getSelection();
  format = mapToQuillFormat(format) as any;
  return (
    <ToolbarButton
      active={!!selection && quill.getFormat()[format] === true}
      onMouseDown={e => {
        e.preventDefault();
        quill.format(format, !quill.getFormat()[format], 'user');
      }}
    >
      {iconMap[format]}
    </ToolbarButton>
  );
};

function mapToQuillFormat(format: Format): string {
  switch (format) {
    case 'strike-through':
      return 'strike';
    default:
      return format;
  }
}

export default FormatButton;
