import { noop, omit, pick } from 'lodash';
import { DeltaOperation, Quill } from 'quill';
import Delta from 'quill-delta';
import React from 'react';
import Typography from '../Typography';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

import QuillEditor, {
  Options,
  QUILL_OPTION_KEYS,
  Range,
  Sources,
} from '../QuillEditor';

const styles = createStyles<'root'>(() => ({
  root: {
    position: 'relative',
    boxSizing: 'border-box',
    margin: 0,
    '& .ql-clipboard': {
      left: '-100000px',
      height: 1,
      overflowY: 'hidden',
      position: 'absolute',
      top: '50%',
    },
    '& .ql-clipboard p': {
      margin: 0,
      padding: 0,
    },
    '& .ql-editor': {
      outline: 'none',
    },
  },
}));

export interface RichTextEditorPluginProps {
  quill: Quill;
  forceSelect: (force?: boolean) => void;
}

export interface RichTextEditorProps
  extends Pick<Options, Exclude<keyof Options, 'theme'>> {
  className?: string;
  defaultValue?: string | DeltaOperation;
  onBlur?: () => void;
  onChange?: (html: string) => void;
  onDelta?: (delta: Delta, oldContents: Delta, source: Sources) => any;
  onFocus?: () => void;
  onLinkClick?: React.MouseEventHandler;
  plugins?: Array<React.ComponentType<RichTextEditorPluginProps>>;
  value?: string | DeltaOperation;
}

interface State {
  editorMounted?: boolean;
  focused?: boolean;
  forceSelect?: boolean;
}

type Props = WithStyles<RichTextEditorProps, typeof styles>;

class RichTextEditor extends React.Component<Props, State> {
  public static defaultProps = {
    formats: [],
    modules: {},
    onBlur: noop,
    onDelta: noop,
    onFocus: noop,
    onLinkClick: noop,
    plugins: [],
  } as any;

  state: State = {};

  private _editor: QuillEditor | null = null;

  public componentDidMount() {
    this.setState({ editorMounted: true });
  }

  public shouldComponentUpdate(prevProps: Props) {
    return (
      prevProps.value !== this.props.value ||
      this.props.className !== prevProps.className
    );
  }

  public render() {
    const { classes, className, theme, ...rest } = this.props;
    return (
      <Typography
        className={cx(
          classes.root,
          // this.state.forceSelect && styles.forceSelect,
          className,
        )}
        {...omit(
          rest,
          'defaultValue',
          'onBlur',
          'onChange',
          'onDelta',
          'onFocus',
          'onLinkClick',
          'plugins',
          'value',
          ...QUILL_OPTION_KEYS,
        ) as any}
      >
        <QuillEditor
          ref={this._setEditorRef}
          onEnableChange={this._handleEnabledChange}
          onSelectionChange={this._handleSelectionChange}
          onTextChange={this._handleTextChange}
          onClick={this._handleClick}
          {...pick(rest, 'defaultValue', 'value', ...QUILL_OPTION_KEYS) as any}
        />
        {this.state.editorMounted &&
          this.props.plugins!.map((Plugin, i) => (
            <Plugin
              forceSelect={this._forceSelect}
              key={i}
              quill={this._editor!.quill}
            />
          ))}
      </Typography>
    );
  }

  private _forceSelect = (forceSelect: boolean = true) => {
    this.setState({ forceSelect });
  };

  private _setEditorRef = (ref: any) => {
    this._editor = ref;
  };

  private _handleTextChange = (
    delta: any,
    oldContents: Delta,
    source: Sources,
  ) => {
    const { onChange, onDelta } = this.props;
    onDelta!(delta, oldContents, source);
    if (onChange) {
      onChange(this._editor!.html);
    }
    this.forceUpdate();
  };

  private _handleSelectionChange = (range: Range, oldRange: Range) => {
    this.forceUpdate();
    if (range === null && oldRange !== null) this.props.onBlur!();
    if (range !== null && oldRange === null) this.props.onFocus!();
  };

  private _handleClick = (e: any) => {
    if (e.target.tagName === 'A') {
      this.props.onLinkClick!(e);
      if (!this.props.readOnly) {
        e.preventDefault();
      }
    }
  };

  private _handleEnabledChange = () => {
    this.forceUpdate();
  };
}

export default withStyles(styles)(RichTextEditor);
