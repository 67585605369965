import { Camera } from '@robotsnacks/icons';
import React, { CSSProperties, ReactNode, SFC } from 'react';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<
  | 'compact'
  | 'dragLabel'
  | 'dragover'
  | 'dropLabel'
  | 'empty'
  | 'icon'
  | 'messages'
  | 'root'
  | 'transition'
  | 'vertical'
>(theme => ({
  root: {
    ...theme.font('control'),
    alignItems: 'center',
    backgroundColor: theme.color('grey', '50'),
    boxSizing: 'border-box',
    color: theme.color('text.secondary'),
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    opacity: 0,
    outlineOffset: -6,
    outlineStyle: 'dashed',
    outlineWidth: 1,
    outlineColor: 'transparent',
    padding: 6,
    position: 'absolute',
    transition: 'background-color 300ms, opacity 300ms, outline-color 300ms',
    userSelect: 'none',
    width: '100%',
    zIndex: 2,
  },
  icon: {
    fontSize: 30,
    opacity: 0.3,
    marginRight: 10,
    marginBottom: 0,
  },
  messages: {
    fontSize: 12,
    opacity: 0.95,
    position: 'relative',
  },
  dragLabel: {
    opacity: 1,
  },
  dropLabel: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    opacity: 0,
  },
  transition: {
    transition: 'opacity 300ms',
  },
  vertical: {
    flexDirection: 'column',
    textAlign: 'center',
    '& .icon': {
      marginRight: 0,
      marginBottom: 10,
    },
  },
  compact: {
    '& $messages': {
      display: 'none',
    },
    '& $icon': {
      marginRight: 0,
    },
  },
  dragover: {
    backgroundColor: theme.color('grey', '100'),
    outlineColor: theme.color('grey', '300'),
    opacity: 0.9,
    '& $dragLabel': {
      opacity: 0,
    },
    '& $dropLabel': {
      opacity: 1,
    },
  },
  empty: {
    opacity: 1,
  },
}));

export interface DropAreaProps {
  children?: never;
  compact?: boolean;
  dragLabel?: ReactNode;
  dragover?: boolean;
  dropLabel?: ReactNode;
  empty?: boolean;
  style?: CSSProperties;
  vertical?: boolean;
}

type Props = WithStyles<DropAreaProps, typeof styles>;

const DropArea: SFC<Props> = ({
  classes,
  compact,
  dragover,
  dragLabel,
  dropLabel,
  empty,
  style,
  vertical,
}) => (
  <div
    className={cx(
      classes.root,
      empty && classes.empty,
      vertical && classes.vertical,
      compact && classes.compact,
      dragover && classes.dragover,
    )}
    style={style}
  >
    <Camera className={cx(classes.transition, classes.icon)} />
    <div className={classes.messages}>
      {dragLabel && (
        <span className={cx(classes.transition, classes.dragLabel)}>
          Drag an image here to upload.
        </span>
      )}
      {dropLabel && (
        <span className={cx(classes.transition, classes.dropLabel)}>
          Drop to start uploading.
        </span>
      )}
    </div>
  </div>
);

export default withStyles(styles)(DropArea);
