export interface BemScoped {
  block: (block: string) => string;
  className: string;
  active: () => string;
  dense: () => string;
  disabled: () => string;
  empty: () => string;
  error: () => string;
  focused: () => string;
  hasValue: () => string;
  modifier: (modifier: string) => string;
  warning: () => string;
}

export interface BemStatic {
  active: (className: string) => string;
  block: (className: string, block: string) => string;
  dense: (className: string) => string;
  disabled: (className: string) => string;
  empty: (className: string) => string;
  error: (className: string) => string;
  focused: (className: string) => string;
  hasValue: (className: string) => string;
  modifier: (className: string, modifier: string) => string;
  warning: (className: string) => string;
}

type Bem = ((className: string, blockName?: string) => BemScoped) & BemStatic;

const block = (className: string, block: string): string =>
  [className, block].join('__');

const modifier = (className: string, modified: string): string =>
  [className, modified].join('--');

const active = (className: string): string => modifier(className, 'active');

const focused = (className: string): string => modifier(className, 'focused');

const dense = (className: string): string => modifier(className, 'dense');

const disabled = (className: string): string => modifier(className, 'disabled');

const empty = (className: string): string => modifier(className, 'empty');

const error = (className: string): string => modifier(className, 'error');

const hasValue = (className: string): string =>
  modifier(className, 'has-value');

const warning = (className: string): string => modifier(className, 'warn');

export const bem: Bem = ((className: string, blockName?: string): BemScoped => {
  className = blockName ? block(className, blockName) : className;
  return {
    active: active.bind(null, className),
    block: block.bind(null, className),
    className,
    dense: dense.bind(null, className),
    disabled: disabled.bind(null, className),
    empty: empty.bind(null, className),
    error: error.bind(null, className),
    focused: focused.bind(null, className),
    hasValue: hasValue.bind(null, className),
    modifier: modifier.bind(null, className),
    warning: warning.bind(null, className),
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}) as any;

bem.active = active;
bem.block = block;
bem.modifier = modifier;
bem.empty = focused;
bem.focused = focused;
bem.dense = dense;
bem.disabled = disabled;
bem.hasValue = disabled;
bem.error = error;
bem.warning = warning;
