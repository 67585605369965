import React, { HTMLAttributes, SFC } from 'react';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root'>(() => ({
  root: {
    '& > *': {
      margin: '0.25em',
    },
    '& > *:first-child': {
      marginLeft: 0,
    },
    '& > *:last-child': {
      marginRight: 0,
    },
  },
}));

export interface ButtonRowProps extends HTMLAttributes<HTMLDivElement> {}

type Props = WithStyles<ButtonRowProps, typeof styles>;

const ButtonRow: SFC<Props> = ({
  children,
  classes,
  className,
  theme,
  ...rest
}) => (
  <div className={cx(classes.root, className)} {...rest}>
    {children}
  </div>
);

export default withStyles(styles)(ButtonRow);
