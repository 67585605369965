import color from 'color';
import { clamp, range, round } from 'lodash';

const SCALE_STEP = 0.07;

/**
 * Calculates the x-value on a luminosity curve.
 * @param c Luminosity.
 */
const calcLogXValue = (c: number) =>
  Math.pow(Math.E, (c * Math.log(2)) / 120) - 1;

/**
 * Calculates the y-value on a luminosity curve.
 * @param c Luminosity.
 */
const calcLogYValue = (c: number) => (120 / Math.log(2)) * Math.log(c + 1);

/**
 * Generates set of luminosity values.
 * @param center Center luminosity value.
 */
export const luminosities = (center: number) => {
  // Where (x-coordinate) the input number lies on the logarithmic scale.
  const x = calcLogXValue(center);
  return range(-4, 6)
    .map(n => calcLogYValue(x - (n - 1) * SCALE_STEP))
    .map(Math.round)
    .map(n => clamp(n, 0, 100));
};

/**
 * Generates a set of shaded based on the input color.
 * @param clr Input color.
 */
export default (clr: string): string[] =>
  luminosities(round(color(clr).lightness())).map(lum =>
    color(clr)
      .lightness(lum)
      .rgb()
      .string(),
  );
