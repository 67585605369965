type Callable<T, U extends any[]> = (...args: U) => T;

export default <T, U extends any[]>(f: T | Callable<T, U>) => (
  ...args: U
): T => {
  if (typeof f === 'function') {
    return (f as Callable<T, U>)(...args);
  }
  return f;
};
