import { noop } from 'lodash';
import { CSSLock, getClientCoordinates, lock } from '../utils';
import DragContext from './DragContext';

import React, {
  Component,
  MouseEventHandler,
  ReactElement,
  ReactNode,
  TouchEventHandler,
  cloneElement,
} from 'react';

interface DragControllerChildProps {
  dragging: boolean;
  endDrag: () => void;
  left: number;
  startDrag: MouseEventHandler<HTMLElement> | TouchEventHandler<HTMLElement>;
  top: number;
}

export interface DragEvent {
  left: number;
  target: HTMLElement;
  top: number;
}

export type DragEventHandler = (event: DragEvent) => void;

export interface DragControllerProps {
  children:
    | ReactElement<DragControllerChildProps>
    | ((props: DragControllerChildProps) => ReactNode);
  left?: number;
  onAfterDragEnd?: DragEventHandler;
  onBeforeDragStart?: DragEventHandler;
  onDrag?: DragEventHandler;
  onDragEnd?: DragEventHandler;
  onDragStart?: DragEventHandler;
  target: HTMLElement | null | (() => HTMLElement | null);
  top?: number;
}

type Props = DragControllerProps & typeof defaultProps;

type State = {
  dragging?: boolean;
  left?: number;
  top?: number;
  startClientX?: number;
  startClientY?: number;
  startLeft?: number;
  startTop?: number;
};

const DRAG_EVENTS: Array<'mousemove' | 'touchmove'> = [
  'mousemove',
  'touchmove',
];

const END_EVENTS: Array<'mouseup' | 'touchend' | 'touchcancel'> = [
  'mouseup',
  'touchend',
  'touchcancel',
];

const defaultProps = Object.freeze({
  onAfterDragEnd: noop,
  onBeforeDragStart: noop,
  onDrag: noop,
  onDragEnd: noop,
  onDragStart: noop,
});

const getInitialState = (props: Props): State => ({
  dragging: false,
  left: props.left || 0,
  startClientX: 0,
  startClientY: 0,
  startLeft: 0,
  startTop: 0,
  top: props.top || 0,
});

/**
 *
 * @param target
 * @param state
 */
const buildDragEvent = (target: HTMLElement, state: State): DragEvent => ({
  target,
  left: state.left || 0,
  top: state.top || 0,
});

/**
 *
 */
export default class Drag extends Component<Props, State> {
  static defaultProps = defaultProps;
  state = getInitialState(this.props);

  private _selectLock: CSSLock | null = null;

  componentWillUnmount() {
    if (this._selectLock) this._selectLock.unlock();
  }

  /**
   *
   */
  render() {
    const { children } = this.props;
    if (children) {
      return (
        <DragContext.Provider
          value={{
            endDrag: this._endDrag,
            startDrag: this._startDrag,
          }}
        >
          {this._renderChildren()}
        </DragContext.Provider>
      );
    }

    return null;
  }

  /**
   *
   */
  private _renderChildren = (): ReactNode => {
    const { children, left, top } = this.props;
    const props: DragControllerChildProps = {
      dragging: this.state.dragging || false,
      endDrag: this._endDrag,
      left: typeof left === 'number' ? left : this.state.left || 0,
      startDrag: this._startDrag,
      top: typeof top === 'number' ? top : this.state.top || 0,
    };
    if (typeof children === 'function') {
      return children(props);
    } else {
      return cloneElement(
        children as ReactElement<DragControllerChildProps>,
        props,
      );
    }
  };

  /**
   *
   */
  private _startDrag = (
    e: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>,
  ) => {
    const current = this._getTarget();
    if (!current) return;

    this._selectLock = lock('body', 'select');

    const { clientX, clientY } = getClientCoordinates(e)!;
    const before = buildDragEvent(current, this.state);
    this.props.onBeforeDragStart(before);

    setTimeout(() => {
      const { left: l, top: t } = this.props;
      const startLeft = typeof l === 'number' ? l : this.state.left;
      const startTop = typeof t === 'number' ? t : this.state.top;

      this.setState(
        {
          startLeft,
          startTop,
          dragging: true,
          left: startLeft,
          startClientX: clientX,
          startClientY: clientY,
          top: startTop,
        },
        () => {
          const event = buildDragEvent(current, this.state);
          this.props.onDragStart(event);
        },
      );

      DRAG_EVENTS.forEach(event =>
        document.addEventListener(event, this._handleDrag),
      );

      END_EVENTS.forEach(event =>
        document.addEventListener(event, this._endDrag),
      );

      document.addEventListener('mouseout', this._handleMouseOut);
    }, 0);
  };

  /**
   *
   */
  private _handleMouseOut = (e: MouseEvent) => {
    // If this is a mouseout event, ensure it qualifies as leaving the window.
    if (e && e.type === 'mouseout') {
      const from = e.relatedTarget as HTMLElement;
      if (from && from.nodeName !== 'HTML') {
        return;
      }
    }
    this._endDrag();
  };

  /**
   *
   */
  private _endDrag = () => {
    const current = this._getTarget();
    // Guard against calling endDrag multiple times, or if there's no ref.
    if (!this.state.dragging || !current) return;
    if (this._selectLock) this._selectLock.unlock();

    DRAG_EVENTS.forEach(evt =>
      document.removeEventListener(evt, this._handleDrag),
    );

    END_EVENTS.forEach(evt => document.removeEventListener(evt, this._endDrag));

    const event = buildDragEvent(current, this.state);
    this.props.onDragEnd(event);

    this.setState({ dragging: false }, () => {
      const after = buildDragEvent(current, this.state);
      this.props.onAfterDragEnd(after);
    });
  };

  /**
   *
   */
  private _handleDrag = (e: MouseEvent | TouchEvent) => {
    const {
      startClientX = 0,
      startClientY = 0,
      startTop = 0,
      startLeft = 0,
    } = this.state;

    const { clientX, clientY } = getClientCoordinates(e)!;

    const left = clientX - startClientX + startLeft;
    const top = clientY - startClientY + startTop;

    this.setState({ left, top }, () => {
      const current = this._getTarget();
      if (!current) return;
      const event = buildDragEvent(current, this.state);
      this.props.onDrag(event);
      current.dispatchEvent(
        new CustomEvent('taffy:drag', { detail: event, bubbles: true }),
      );
    });
  };

  private _getTarget(): HTMLElement | null {
    const { target } = this.props;
    if (typeof target === 'function') return target();
    return target;
  }
}
