import { forEach, includes, isElement, isString } from 'lodash';
import random from './random';

export type CSSLock = {
  unlock: () => void;
};

type Lock = 'cursor' | 'select' | 'scroll';

const getElement = (selector: string | HTMLElement) => {
  if (!isElement(selector) && !isString(selector)) {
    throw new Error('Valid HTMLElement or string must be passed.');
  }
  if (isString(selector)) return document.querySelector(selector);
  return selector;
};

const applyClassToSelector = (
  selector: string | HTMLElement,
  className: string,
) => {
  const el = getElement(selector);
  if (el !== null) {
    el.classList.add(className);
  }
};

const removeLockClass = (className: string) => {
  const el = document.querySelector(`.${className}`);
  if (el !== null) {
    el.classList.remove(className);
  }
};

const makeApplicator = (styles: string) => {
  let applied = false;
  return () => {
    if (!applied && typeof window !== 'undefined' && document.head) {
      const style = document.createElement('style');
      style.innerHTML = styles;
      document.head.appendChild(style);
      applied = true;
    }
  };
};

const applicator = makeApplicator(`
  [class*="scroll-lock-"] {
    overflow: hidden !important;
  }
  [class*="select-lock-"] {
    user-select: none !important;
  }
  [class*="cursor-lock-"],
  [class*="cursor-lock-"] * {
    cursor: none !important;
  }
`);

const makeScrollLockClass = (key: string) => `scroll-lock-${key}`;
const makeCursorLockClass = (key: string) => `cursor-lock-${key}`;
const makeSelectLockClass = (key: string) => `select-lock-${key}`;

const lockClassGenerators = {
  cursor: makeCursorLockClass,
  scroll: makeScrollLockClass,
  select: makeSelectLockClass,
};

export default (selector: string | HTMLElement, ...locks: Lock[]): CSSLock => {
  applicator();
  const key = random();

  forEach(lockClassGenerators, (generator, type) => {
    if (includes(locks, type)) {
      applyClassToSelector(selector, generator(key));
    }
  });

  return {
    unlock: () => {
      forEach(lockClassGenerators, generator => {
        removeLockClass(generator(key));
      });
    },
  };
};
