import { amber } from './amber';
import { blue } from './blue';
import { blueGrey } from './blue-grey';
import { brown } from './brown';
import { common } from './common';
import { cyan } from './cyan';
import { deepOrange } from './deep-orange';
import { deepPurple } from './deep-purple';
import { green } from './green';
import { grey } from './grey';
import { indigo } from './indigo';
import { lightBlue } from './light-blue';
import { lightGreen } from './light-green';
import { lime } from './lime';
import { orange } from './orange';
import { pink } from './pink';
import { purple } from './purple';
import { red } from './red';
import { teal } from './teal';
import { yellow } from './yellow';

export const palettes = {
  amber,
  blue,
  blueGrey,
  brown,
  common,
  cyan,
  deepOrange,
  deepPurple,
  green,
  grey,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
};
