import bytes from 'bytes';
import { escapeRegexp } from '../utils';

import {
  castArray,
  debounce,
  isNumber,
  isString,
  map,
  noop,
  some,
  without,
} from 'lodash';

import React, {
  DragEvent,
  DragEventHandler,
  PureComponent,
  ReactNode,
} from 'react';

const DRAG_LEAVE_DEBOUNCE = 100;

export enum UnacceptableReason {
  MaxFileCountExceeded = 'max-file-count-exceeded',
  MaxFileSizeExceeded = 'max-file-size-exceeded',
  MinFileSizeNotMet = 'min-file-size-not-met',
  MinFileCountNotMet = 'min-file-count-not-met',
  UnacceptableType = 'unacceptable-type',
}

export type UnacceptableFileError = Error & {
  reason: UnacceptableReason;
  file: File;
};

export interface IDropzoneRenderProps {
  dragover: boolean;
  files: File[];
  unacceptable: UnacceptableFileError[];
  onDragEnter?: DragEventHandler;
  onDragLeave?: DragEventHandler;
  onDragOver?: DragEventHandler;
  onDrop?: DragEventHandler;
}

export interface DropzoneProps {
  accept: string | string[];
  children: (props: IDropzoneRenderProps) => ReactNode;
  disabled?: boolean;
  maxFileCount?: number;
  maxFileSize?: string | number;
  minFileCount?: number;
  minFileSize?: string | number;
  onDragEnter: DragEventHandler;
  onDragLeave: DragEventHandler;
  onDragOver: DragEventHandler;
  onDrop: (
    valid: File[],
    invalid: UnacceptableFileError[],
    e: DragEvent,
  ) => void;
}

type Props = DropzoneProps;

interface State {
  dragover?: boolean;
  files?: File[];
  unacceptable?: UnacceptableFileError[];
}

const defaultProps = Object.freeze({
  accept: [] as string[],
  children: () => null,
  onDragEnter: noop,
  onDragLeave: noop,
  onDragOver: noop,
  onDrop: noop,
});

const initialState = Object.freeze({
  dragover: false,
  files: [],
  unacceptable: [],
});

export default class Dropzone extends PureComponent<Props, State> {
  static defaultProps = defaultProps;
  state = initialState;

  private _handleDragLeave = debounce((e: React.DragEvent) => {
    this.props.onDragLeave(e);
    this.setState({ dragover: false });
  }, DRAG_LEAVE_DEBOUNCE);

  public render() {
    const { children } = this.props;
    return children(this._getRenderProps());
  }

  private _getRenderProps(): IDropzoneRenderProps {
    const { dragover, files, unacceptable } = this.state;
    return {
      dragover,
      files,
      unacceptable,
      onDragEnter: this._handleDragEnter,
      onDragLeave: this._handleDragLeave,
      onDragOver: this._handleDragOver,
      onDrop: this._handleDrop,
    };
  }

  private _handleDragEnter = (e: React.DragEvent) => {
    if (this._isDisabled()) return;
    e.preventDefault();
    e.stopPropagation();
    this.props.onDragEnter(e);
    this.setState({ dragover: true });
  };

  private _handleDragOver = (e: React.DragEvent) => {
    if (this._isDisabled()) return;
    e.preventDefault();
    this._handleDragLeave.cancel();
    e.dataTransfer.dropEffect = 'copy';
    this.props.onDragOver(e);
  };

  private _handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const allFiles = Array.from(e.dataTransfer.files);
    const files = this._pickAcceptableFiles(allFiles);
    const unacceptableFiles = without(allFiles, ...files);
    const unacceptable = this._getUnacceptableFileErrors(
      unacceptableFiles,
      files.length,
    );
    this.props.onDrop(files, unacceptable, e);
    this.setState({
      files,
      unacceptable,
      dragover: false,
    });
  };

  private _getUnacceptableFileErrors(
    files: File[],
    acceptedCount: number,
  ): UnacceptableFileError[] {
    return map(files, file => {
      const err = new Error('Unacceptable file.') as UnacceptableFileError;
      err.file = file;
      err.reason = this._getUnacceptableFileErrorReason(file, acceptedCount);
      return err;
    });
  }

  private _getUnacceptableFileErrorReason(
    file: File,
    acceptedCount: number,
  ): UnacceptableReason {
    const accept = this._getAcceptRegexps();
    if (file.size > this._getMaxFileSize()) {
      return UnacceptableReason.MaxFileSizeExceeded;
    }
    if (file.size < this._getMinFileSize()) {
      return UnacceptableReason.MinFileSizeNotMet;
    }
    if (!some(accept, r => r.test(file.type))) {
      return UnacceptableReason.UnacceptableType;
    }
    if (acceptedCount >= this._getMaxFileCount()) {
      return UnacceptableReason.MaxFileCountExceeded;
    }
    if (acceptedCount <= this._getMinFileCount()) {
      return UnacceptableReason.MinFileCountNotMet;
    }
    throw new Error('Unable to determine reason file is unacceptable.');
  }

  private _pickAcceptableFiles(files: File[]) {
    const maxFileSize = this._getMaxFileSize();
    const minFileSize = this._getMinFileSize();
    const accept = this._getAcceptRegexps();
    return files
      .filter(file => file.size <= maxFileSize)
      .filter(file => file.size >= minFileSize)
      .filter(file =>
        accept.length > 0 ? some(accept, r => r.test(file.type)) : true,
      )
      .slice(0, this._getMaxFileCount());
  }

  private _getMaxFileCount() {
    return this.props.maxFileCount || Infinity;
  }

  private _getMaxFileSize() {
    return this._getFileSize(this.props.maxFileSize, Infinity);
  }

  private _getMinFileCount() {
    return this.props.minFileCount || 0;
  }

  private _getMinFileSize() {
    return this._getFileSize(this.props.maxFileSize, 0);
  }

  private _getFileSize(
    size: string | number | undefined,
    notSetValue: number,
  ): number {
    if (!size) return notSetValue;
    if (isNumber(size)) return size;
    if (isString(size)) return bytes(size);
    throw new Error('Invalid file size prop.');
  }

  private _getAcceptRegexps(): RegExp[] {
    const { accept } = this.props;
    return castArray(accept)
      .map(escapeRegexp)
      .map(g => g.replace(/\\\*/g, '[^/]*'))
      .map(g => new RegExp(`^${g}$`));
  }

  private _isDisabled() {
    return this.props.disabled === true;
  }
}
