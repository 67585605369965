import {
  Theme,
  TypographyVariant,
  WithTheme,
  bem,
  cx,
  interpolateFont,
  interpolateTextColor,
} from '@mint/core';
import { useFocus, useFormControlClassNames, useValue } from '../shared';
import { BaseInputProps } from './base-input-props';
import React from 'react';
import styled from 'styled-components';

/**
 * Base input root class name applied to the input element.
 */
export const ROOT_CLASS_NAME = 'base-input';

/**
 * Helper function to create input element properties.
 * @param props BaseInput component properties.
 * @param theme Resolved theme instance.
 * @param focused Flag indicating if the input has focus.
 */
const makeInputProps = (
  props: BaseInputProps,
  focused: boolean,
  hasValue: boolean,
): React.InputHTMLAttributes<HTMLInputElement> => {
  const { className, dense } = props;
  const classes = bem(ROOT_CLASS_NAME);
  return {
    className: cx(
      ROOT_CLASS_NAME,
      useFormControlClassNames(ROOT_CLASS_NAME, { ...props, focused }),
      dense && classes.dense(),
      hasValue ? classes.hasValue() : classes.empty(),
      className,
    ),
  };
};

/**
 * `BaseInput` component implementation before styling.
 */
const BaseInputComponent = React.forwardRef<HTMLInputElement, BaseInputProps>(
  (props, ref): React.ReactElement => {
    const {
      children,
      className,
      dense,
      disabled,
      error,
      hidePlaceholder,
      onBlur,
      onChange,
      onFocus,
      theme: _,
      warning,
      ...rest
    } = props;

    const [focused, bindFocus] = useFocus({ onBlur, onFocus });
    const [value, handleChange] = useValue({ onChange });

    const inputProps = {
      ...makeInputProps(props, focused, value.length > 0),
      ...rest,
      ...bindFocus,
      onChange: handleChange,
      ref,
    };

    return <input {...inputProps} />;
  },
);

BaseInputComponent.displayName = 'BaseInput';

const pickFont = (props: WithTheme<BaseInputProps>): TypographyVariant =>
  props.dense ? 'dense' : 'control';

const interpolateHidePlaceholder = (
  props: WithTheme<BaseInputProps>,
): string => {
  if (props.hidePlaceholder) {
    return `opacity: 0;`;
  } else {
    return '';
  }
};

export const BaseInput = styled(BaseInputComponent)`
  background: rgba(0, 0, 0, 0);
  border: 0;
  outline: 0;
  padding: 0;
  width: 100%;

  ${interpolateFont(pickFont, 'text.primary')}

  ::placeholder {
    ${interpolateTextColor('text.hint')}
    ${interpolateHidePlaceholder}
  }
`;
