import React, { useCallback, useEffect, useRef, useState } from 'react';
import { head, without } from 'lodash';
import { InkBlot } from '../ink-blot';
import { getEventPointerCoordinates } from '@robotsnacks/dom';
import produce from 'immer';
// import { InkProps, UseBlotsValue } from '../interfaces';

/**
 * Default ink speed.
 */
export const DEFAULT_INK_SPEED = 500;

/**
 * Minimal key counter for ink blots.
 */
let key = 0;

export interface BindUseInk<T extends Element = Element> {
  onMouseDown: React.MouseEventHandler<T>;
  onMouseUp: React.MouseEventHandler<T>;
  onTouchStart: React.TouchEventHandler<T>;
  onTouchEnd: React.TouchEventHandler<T>;
}

export interface UseInkOptions<T extends Element = Element>
  extends Partial<BindUseInk<T>> {
  center?: boolean;
  contain?: boolean;
  disabled?: boolean;
  speed?: number;
}

/**
 * Internal interface for blot entry object which represent the animation state,
 * blot center, key, and radius.
 */
interface BlotEntry {
  /**
   * Set to `true` if the blot is currently animated "in".
   */
  in: boolean;

  /**
   * Center of the blot `x` coordinate.
   */
  cx: number;

  /**
   * Center of the blot `y` coordinate.
   */
  cy: number;

  /**
   * Blot component key.
   */
  key: number;

  /**
   * Maximum radius of the blot.
   */
  r: number;

  /**
   * Timestamp when the blot should be unmounted.
   */
  unmountAt?: number;
}

/**
 * Internal helper function to calculate blot style.
 * @param entry Blot entry object.
 */
const blotStyle = ({ cx, cy, r }: BlotEntry): React.CSSProperties => {
  return {
    height: `${r * 2}px`,
    left: `${cx - r}px`,
    top: `${cy - r}px`,
    width: `${r * 2}px`,
  };
};

/**
 * Function to calculate the hypotenuse of two numbers.
 * @param a First side.
 * @param b Second site.
 */
const hypotenuse = (a: number, b: number): number => Math.sqrt(a ** 2 + b ** 2);

/**
 * Internal helper function which calculates the size and position of an ink
 * blot.
 * @param props Ink component properties.
 * @param el Ref to span element.
 * @param coords Pointer coordinates of event that triggered the ink.
 */
const createBlotEntry = <T extends Element = Element>(
  options: UseInkOptions<any>,
  el: T,
  coords: { clientX: number; clientY: number },
): BlotEntry => {
  let cy: number;
  let cx: number;

  const { center, contain } = options;
  const { clientX, clientY } = coords;
  const rect = el.getBoundingClientRect();

  if (center) {
    cx = rect.width / 2;
    cy = rect.height / 2;
  } else {
    cx = clientX - rect.left;
    cy = clientY - rect.top;
  }

  const width = Math.max(rect.width - cx, cx);
  const height = Math.max(rect.height - cy, cy);

  const maxRadius = contain
    ? Math.min(width, height)
    : hypotenuse(width, height);

  return { cx, cy, r: maxRadius, in: true, key: ++key };
};

const useBlotState = (): [
  BlotEntry[],
  React.MutableRefObject<BlotEntry[]>,
  (entries: BlotEntry[]) => void,
] => {
  const [blots, setBlotsState] = useState<BlotEntry[]>([]);
  const ref = useRef<BlotEntry[]>([]);
  return [
    blots,
    ref,
    (blots: BlotEntry[]): void => {
      ref.current = blots;
      setBlotsState(blots);
    },
  ];
};

const useAddBlot = (
  options: UseInkOptions<Element>,
  currentEntries: BlotEntry[],
  setEntries: (entries: BlotEntry[]) => void,
): ((e: React.MouseEvent<Element> | React.TouchEvent<Element>) => void) =>
  useCallback(
    (e): void => {
      const coords = getEventPointerCoordinates(e as any);
      const nextEntries = produce(currentEntries, (draft): void => {
        draft.unshift(createBlotEntry(options, e.currentTarget, coords));
      });
      setEntries(nextEntries);
    },
    [options, currentEntries, setEntries],
  );

const useScheduleCleanEntries = (
  speed: number,
  entries: React.MutableRefObject<BlotEntry[]>,
  setEntries: (entries: BlotEntry[]) => void,
): (() => void) => {
  // List of pending timeouts which must be removed on unmount.
  const timeouts = useRef<NodeJS.Timeout[]>([]);

  // Clears all pending timeouts on unmount.
  useEffect(
    (): (() => void) => (): void =>
      timeouts.current.forEach((t): void => clearTimeout(t)),
    [],
  );

  return useCallback((): void => {
    const timeout = setTimeout((): void => {
      const now = Date.now();
      const nextEntries = entries.current.filter(
        (blot): boolean => blot.unmountAt == null || blot.unmountAt > now,
      );
      timeouts.current = without(timeouts.current, timeout as any);
      setEntries(nextEntries);
    }, speed);
    timeouts.current.push(timeout as any);
  }, [speed, entries, setEntries]);
};

const useRemoveBlot = (
  speed: number,
  currentEntries: BlotEntry[],
  setEntries: (entries: BlotEntry[]) => void,
  scheduleCleanEntries: () => void,
): (() => void) =>
  useCallback((): void => {
    let removeTarget: BlotEntry | undefined;

    const nextEntries = produce(currentEntries, (draft): void => {
      removeTarget = head(draft);
      if (removeTarget) {
        removeTarget.in = false;
        removeTarget.unmountAt = Date.now() + speed;
      }
    });

    if (nextEntries !== currentEntries) {
      setEntries(nextEntries);
      scheduleCleanEntries();
    }
  }, [currentEntries, setEntries, scheduleCleanEntries]);

const useHandlers = <T extends Element = Element>(
  options: UseInkOptions<T>,
  addBlot: (e: React.MouseEvent<Element> | React.TouchEvent<Element>) => void,
  removeBlot: () => void,
): BindUseInk<T> => {
  const onMouseDown = useCallback(
    (e: React.MouseEvent<T>): void => {
      if (!options.disabled) {
        addBlot(e);
      }
      if (options.onMouseDown) {
        options.onMouseDown(e);
      }
    },
    [options.onMouseDown, options.disabled, addBlot, removeBlot],
  );

  const onMouseUp = useCallback(
    (e: React.MouseEvent<T>): void => {
      removeBlot();
      if (options.onMouseUp) {
        options.onMouseUp(e);
      }
    },
    [options.onMouseUp, addBlot, removeBlot],
  );

  const onTouchStart = useCallback(
    (e: React.TouchEvent<T>): void => {
      if (!options.disabled) {
        addBlot(e);
      }
      if (options.onTouchStart) {
        options.onTouchStart(e);
      }
    },
    [options.onTouchStart, options.disabled, addBlot, removeBlot],
  );

  const onTouchEnd = useCallback(
    (e: React.TouchEvent<T>): void => {
      removeBlot();
      if (options.onTouchEnd) {
        options.onTouchEnd(e);
      }
    },
    [options.onTouchEnd, addBlot, removeBlot],
  );

  return { onMouseDown, onMouseUp, onTouchEnd, onTouchStart };
};

/**
 * Hook component which provides event handlers and list of blot entries.
 * @param props Ink component properties.
 * @param span Ref to root ink span element.
 */
export const useInk = <T extends Element = Element>(
  options: UseInkOptions<T>,
): [React.ReactElement[], BindUseInk<T>] => {
  const speed = options.speed || DEFAULT_INK_SPEED;

  const [currentEntries, blotEntriesRef, setBlots] = useBlotState();

  const scheduleClean = useScheduleCleanEntries(
    speed,
    blotEntriesRef,
    setBlots,
  );

  const addBlot = useAddBlot(options, currentEntries, setBlots);

  const removeBlot = useRemoveBlot(
    speed,
    currentEntries,
    setBlots,
    scheduleClean,
  );

  /**
   * Listen for mouseup and blur on the document. These events will cause the
   * oldest blot to be removed in-case the user mouse-ups someplace other than
   * the element with the link or blurs the window.
   */
  useEffect((): (() => void) => {
    document.addEventListener('mouseup', removeBlot);
    document.addEventListener('blur', removeBlot);
    return (): void => {
      document.removeEventListener('mouseup', removeBlot);
      document.removeEventListener('blur', removeBlot);
    };
  }, [removeBlot]);

  const blots = currentEntries
    .map(
      (entry): React.ReactElement => (
        <InkBlot
          speed={speed}
          in={entry.in}
          key={entry.key}
          style={blotStyle(entry)}
        />
      ),
    )
    .reverse();

  return [blots, useHandlers(options, addBlot, removeBlot)];
};
