import { Breakpoint, Theme, TypographyVariant } from '../theme';
import {
  assertTheme,
  rvr,
  rvrCustomProperty,
  vr,
  vrCustomProperty,
} from '../util';
import { Interpolator } from './interpolator';

/**
 * Style helper which computes divider margins at some breakpoint.
 * @param props Divider properties.
 * @param breakpoint Current breakpoint name.
 */
const breakpointMargins = (
  theme: Theme,
  variant: TypographyVariant,
  breakpoint: Breakpoint,
): string => {
  const media = theme.breakpoints.only(breakpoint);
  const margin = rvr(theme, variant, breakpoint);
  const marginCustomProperty = rvrCustomProperty(theme, variant, breakpoint);

  return `
    ${media} {
      margin-top: ${margin};
      margin-top: var(${marginCustomProperty});
    }
  `;
};

/**
 * Style helper which computes divider margins at all breakpoints.
 * @param props Divider component properties.
 */
export const interpolateRhythmMargin = <TProps extends { theme: Theme }>(
  variantOrFn: TypographyVariant | ((props: TProps) => TypographyVariant),
): Interpolator<TProps> => (props: TProps): string => {
  // Compute the variant from parameters.
  const variant =
    typeof variantOrFn === 'function' ? variantOrFn(props) : variantOrFn;

  // Pull assert the theme.
  const theme = assertTheme(props);

  // Build the RVR style string.
  const responsiveRhythm = theme.breakpoints
    .map((breakpoint): string => breakpointMargins(theme, variant, breakpoint))
    .join('\n');

  // Merge-in VR.
  return `
    margin-top: ${vr(theme, variant)};
    margin-top: var(${vrCustomProperty(theme, variant)});
    ${responsiveRhythm}
  `;
};
