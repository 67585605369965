import { bem, cx } from '@mint/core';
import styled, { keyframes } from 'styled-components';
import { InkBlotProps } from './ink-blot-props';
import React from 'react';

/**
 * Base class for the ink blot component.
 */
const ROOT_CLASS_NAME = 'ink-blot';

/**
 * BEM modifier for ink blots animating in.
 */
const IN_MODIFIER = 'in';

/**
 * Default blot easing function.
 */
const BLOT_EASING_FUNCTION = 'cubic-bezier(0.220, 0.490, 0.270, 0.785)';

/**
 * Blot scale keyframes animation.
 */
const scaleAnimation = keyframes`
  0% {
    transform: scale(0);
  }
  90% {
    transform: scale(1);
  }
`;

/**
 * Ink blot component implementation.
 */
export const InkBlotComponent = React.forwardRef<HTMLSpanElement, InkBlotProps>(
  (props, ref): React.ReactElement => {
    const { className, in: inProp, speed, ...rest } = props;

    const componentProps = {
      className: cx(
        ROOT_CLASS_NAME,
        inProp && bem(ROOT_CLASS_NAME).modifier(IN_MODIFIER),
        className,
      ),
      ...rest,
      ref,
    };

    return <span {...componentProps} />;
  },
);

InkBlotComponent.displayName = 'InkBlot';

/**
 * Interpolates the ink blot animation speed.
 * @param props Ink blot props.
 */
const interpolateSpeed = (props: InkBlotProps): string => `${props.speed}ms`;

/**
 * Interpolates the ink blot opacity.
 * @param props Ink blot props.
 */
const interpolateOpacity = (props: InkBlotProps): string =>
  props.in ? '1' : '0';

export const InkBlot = styled(InkBlotComponent)`
  animation: ${scaleAnimation} ${interpolateSpeed} ${BLOT_EASING_FUNCTION};
  background-color: currentColor;
  border-radius: 100%;
  opacity: ${interpolateOpacity};
  pointer-events: none;
  position: absolute;
  transform-origin: center center;
  transition: opacity ${interpolateSpeed} ${BLOT_EASING_FUNCTION};
`;
